import React from 'react';
import { useParams } from 'react-router-dom';
import SideBar from '../../../Components/SideBar';
import Spacing from '../../../Components/Spacing';
import HttpAxios from '../../../Utils/AxiosHttp';
import { SwalRequest } from '../../../Utils/Swal';
import OvertimeExcel from './Components/OvertimeExcel';
import OvertimeFilter from './Components/OvertimeFilter';
import OvertimeTable from './Components/OvertimeTable';
import OvertimeModel from './Models/OvertimeModel';
import moment from 'moment';

export default function Overtime(props) {
    const { token } = useParams();
    const httpAxios = React.useRef(new HttpAxios({
        token: token
    }));
    // const overtime = React.useRef(new OvertimeModel(props.isWeekdays ? OvertimeReportData : OvertimeReportSunData))
    let overtime = React.useRef();
    const overtimeFilterRef = React.useRef();
    const overtimeTableRef = React.useRef();
    const overtimeExcelRef = React.useRef();
  
    let paramOT = {
        PageNumber: 1,
        PageSize: 100,
        WorkTime: props.isWeekdays ? 'OVERTIME' : 'HOLIDAY',
        DateStart: moment().startOf('month').format('YYYY-MM-DD'),
        DateEnd: moment().endOf('month').format('YYYY-MM-DD')
    };

    // let localParam = {
    //     Vehicle: '',
    //     DriverID: '',
    //     StatusID: '',
    // }

    React.useEffect(() => {
        initService();
        if (props?.isEmbed) {
            SideBar.embed();
        }
        // eslint-disable-next-line
    }, []);

    const initService = async () => {
        SwalRequest({
            title: 'Fetching Overtime Data',
            text: 'Please wait...',
            request: async () => {
                
                const [vehicles, drivers, driverRosterOT, otLogs, orderInfo] = await Promise.all([
                    httpAxios.current.post(`Vehicle?Token=${httpAxios.current.token}`),
                    httpAxios.current.post(`Driver?Token=${httpAxios.current.token}`),
                    getDriverRoster(paramOT.DriverID ?? null),
                    httpAxios.current.post(`Driver/overtime/log`, paramOT),
                    getOrderInfo(paramOT)
                ]);

                overtimeFilterRef.current.setDataSource({
                    vehicles: vehicles,
                    drivers: drivers
                });

                processOvertimeData(driverRosterOT, otLogs, orderInfo);
            },
            failedTitle: 'Failed fetch overtime data'
        })
    }

    const processOvertimeData = (driverRosterOT, otLogs, orderInfo) => {
        
        // DRIVER OVERTIME ROSTER
        // Sort Driver Overtime Roster according to Roster Sequence 
        let withRosterSeq = driverRosterOT.length > 0 ? driverRosterOT.filter(data => {
            return data.RosterSequence !== undefined && data.RosterSequence !== null
        }).sort((a, b) => a.RosterSequence - b.RosterSequence) : [];
       
        let withoutRosterSeq = driverRosterOT.length > 0 ? driverRosterOT.filter(data => {
            return data.RosterSequence === undefined || data.RosterSequence === null;
        }).sort((a, b) => a.LastOTTimestamp - b.LastOTTimestamp) : [];
  
        let finalDriverRoster = [];
        finalDriverRoster.push(...withRosterSeq);
        finalDriverRoster.push(...withoutRosterSeq);

        
        // Filter OrderInfo to get Breakdown Jobs
        const arrOIbreakdown = props.isWeekdays ? orderInfo.filter(data => data.MaterialCode.startsWith("Breakdown")) : 
                                                  orderInfo.filter(data => data.MaterialCode.startsWith("Breakdown") && moment(data.DeliveryTimestamp).isoWeekday() === 7 );
      
        // Get preceeding records if any, then push to OTlogs array
        paramOT.LogID = getLastLogID(otLogs);
        paramOT.PageNumber++;
        if (getRemainingOTlogs(paramOT).length > 0) otLogs.push(...getRemainingOTlogs(paramOT));
     
        // Filter OTlogs to get records with Accepted and Rejected status
        const filteredOTlogs = props.isWeekdays ? otLogs.filter(data => data.DriverResponse > 0) : 
                                                  otLogs.filter(data => data.DriverResponse > 0 && moment(data.LogDate).isoWeekday() === 7 );

        // Merge Driver OT Roster with its respective OT logs
        const mergeData = finalDriverRoster.map(record => {
            let result = filteredOTlogs.filter(data => data.DriverID === record.DriverID);
            let otRecord = result.map(data => {
                return {
                    LogDate: data.LogDate,
                    DriverResponse: data.DriverResponse
                }
            });

            record.Overtime = [];
            record.Overtime.push(...otRecord);
            return record;
        });

        // Merge mergeData with Breakdown logs
        let finalData = mergeData.map(record => {
            let compensated = [];
            let result = arrOIbreakdown.filter(data => {
                if (data.AssignedVehicles.length > 0)
                    return data.AssignedVehicles[0].Value === record.Vehicle

                else return [];
            });

            let breakRecord = [];

            // Weekdays Breakdown and Compensation
            if(props.isWeekdays) {
                breakRecord = result.map(data => {
                    let dateCompensate = moment(data.DeliveryTimestamp).isoWeekday() === 6 ?
                        moment(data.DeliveryTimestamp).add(2, 'days').format('YYYY-MMM-DD') : moment(data.DeliveryTimestamp).add(1, 'days').format('YYYY-MMM-DD');
    
                    let isBreakDay = result.filter(brk => moment(brk.DeliveryTimestamp).format('YYYY-MMM-DD') === dateCompensate);
    
                    // Only add compensation on the day without breakdown
                    if (isBreakDay.length === 0) {
                        compensated.push({
                            LogDate: dateCompensate,
                            DriverResponse: 4
                        });
                    }
    
                    return {
                        LogDate: data.DeliveryTimestamp,
                        DriverResponse: 3
                    }
                });
            }

            // Holiday/Sunday Breakdown and Compensation
            else {
                breakRecord = result.map(data => {
                    let dateCompensate = moment(data.DeliveryTimestamp).add(1, 'days').format('YYYY-MMM-DD');
    
                    let isBreakDay = result.filter(brk => moment(brk.DeliveryTimestamp).format('YYYY-MMM-DD') === dateCompensate);
    
                    // Only add compensation on the day without breakdown
                    if (isBreakDay.length === 0) {
                        compensated.push({
                            LogDate: dateCompensate,
                            DriverResponse: 4
                        });
                    }
    
                    return {
                        LogDate: data.DeliveryTimestamp,
                        DriverResponse: 3
                    }
                });
            }

            record.Overtime.push(...compensated);
            record.Overtime.push(...breakRecord);
            return record;
        });

        // Filter local data if DriverResponse/Status is Accepted or Rejected
        if(paramOT.DriverResponse || paramOT.ResponseRef) {
            finalData = finalData.map(data => { 
                let param = paramOT.DriverResponse ? paramOT.DriverResponse : paramOT.ResponseRef;
                let logs = data.Overtime.filter(rec => rec.DriverResponse === param);
                let temp = {};
                temp = data;
                temp.Overtime = [];
                temp.Overtime = logs;

                return temp;
            }).filter(data => {
                return data.Overtime.length > 0;
            })
        }

        // Filter local data if VehicleID is present
        if(paramOT.VehicleID) {
            finalData = finalData.filter(data => {
                return data.Vehicle === paramOT.VehicleID;
            })
        }

        overtime = new OvertimeModel(finalData, paramOT.DateStart);

        overtimeTableRef.current.setDataSource(overtime.getTableData());
        overtimeExcelRef.current.setExcelData({
            fileName: props.isWeekdays ? 'Overtime (Weekdays)' : 'Overtime (Sun PH)',
            otType: props.isWeekdays ? 'OT WeekDay' : 'OT Sun PH',
            dataSource: overtime.getExcelData(),
            reportDate: paramOT.DateStart
        });
    }

    const getLastLogID = data => data.length > 0 ? data.sort((a, b) => b.LogID - a.LogID)[0].LogID : 0;

    const getRemainingOTlogs = async (paramOT) => {
        let resultCount = 0;
        let otLogsRemaining = [];

        // Recursive fetching of data to complete the exact return values
        do {
            // eslint-disable-next-line
            await httpAxios.current.post(`Driver/overtime/log`, paramOT).then((value) => {
                resultCount = value.length;

                if (value.length > 0) {
                    paramOT.PageNumber++;
                    paramOT.LogID = getLastLogID(value);
                }

                otLogsRemaining.push(...value);
            });
        } while (resultCount > 0);

        return otLogsRemaining;
    };

    const getDriverRoster = async (filterParam) => {
        let requestCount = 200;
        let roster = [];
       
        // Recursive fetching of data to complete the exact return values
        do {
            // eslint-disable-next-line
            await httpAxios.current.post(`Driver/overtime?WorkTime=${props.isWeekdays ? `OVERTIME` : `HOLIDAY`}&RecordCount=${requestCount}&Notify=false`).then((value) => {
                if (value.length < requestCount) { roster.push(...value); }
                else { requestCount += 50; }
            });
        } while (roster.length === 0);
      
        return filterParam === null ? roster : roster.filter(data => data.DriverID === filterParam);
    };

    const getOrderInfo = async (paramOT) => {
        let resultCount = 0;
        let orderList = [];
        let paramOrder = {
            MaterialCode: "Breakdown and Other",                // Need to refactor waiting for PJs feedback regarding OrderInfo endpoint
            DateStart: paramOT.DateStart,
            DateEnd: paramOT.DateEnd,
            PageNumber: 1,
            PageSize: 200
        }

        // Recursive fetching of data to complete the exact return values
        do {
            // eslint-disable-next-line
            await httpAxios.current.post(`Order?token=${httpAxios.current.token}`, paramOrder).then((value) => {
                resultCount = value.length;
                paramOrder.PageNumber++;

                orderList.push(...value);
            });
        } while (resultCount > 0);

        return orderList;
    };

    const onFilterHandler = React.useCallback((filter) => {
       
        // API Filter Parameters
        delete paramOT['LogID'];
        paramOT.DateStart = moment(filter.Date).startOf('month').format('YYYY-MM-DD');
        paramOT.DateEnd = moment(filter.Date).endOf('month').format('YYYY-MM-DD');
        paramOT.PageNumber = 1;
        paramOT.PageSize = 100;

        filter.DriverID ? paramOT.DriverID = filter.DriverID : delete paramOT['DriverID'];
        filter.Status && (filter.Status === 1 || filter.Status === 2) ? paramOT.DriverResponse = filter.Status : delete paramOT['DriverResponse'];
        filter.VehicleID ? paramOT.VehicleID = filter.VehicleID : delete paramOT['VehicleID'];            // Use for local filtering only

        if(filter.Status) {
            if(filter.Status === 1 || filter.Status === 2) {
                paramOT.DriverResponse = filter.Status;
            }
            else {
                paramOT.ResponseRef = filter.Status;
                delete paramOT['DriverResponse']
            }
            
        }
        else {
            delete paramOT['DriverResponse'];
            delete paramOT['ResponseRef'];
        }
       
        initService();
        // eslint-disable-next-line
    }, []);

    const excelExportHandler = () => {
        overtimeExcelRef.current.export();
    }


    return (
        <>
            <OvertimeFilter ref={overtimeFilterRef} onFilter={onFilterHandler} />
            <Spacing />
            <OvertimeTable ref={overtimeTableRef} onExcelExport={excelExportHandler} />
            <OvertimeExcel ref={overtimeExcelRef} />
        </>
    );
}
