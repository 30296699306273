import { TooltipComponent } from "@syncfusion/ej2-react-popups";

//Support React-Feather Icons
function IconButton(props) {

    const styles = {
        iconContainer: {
            marginLeft: props.spacing ? '5px' : '0',
            width: props.containerWidth,
            cursor: 'pointer',
            float: 'left'
        },
        label: {
            position: 'relative',
            top: '-5px',
            display: 'inline-block',
            fontSize: '14px',
            fontWeight: '400'
        },
        buttonContainer: props.label ? {
            display: 'inline-block'
        } : {}
    }

    return (
        <TooltipComponent 
            id={props.id}
            className="tooltip-box" 
            content={props.tooltip} 
            openDelay={props.openDelay} 
            closeDelay={props.closeDelay}
            onClick={props.onClick}
            position='BottomCenter'
            style={styles.iconContainer}>
            <div className="icon-btn-container" style={styles.buttonContainer}>
                <props.icon width={props.width} style={styles.buttonContainer}/>
                {props.label && <span style={styles.label}>New</span>}
            </div>
        </TooltipComponent>
    );
}

IconButton.defaultProps = {
    spacing: false,
    width: 30,
    containerWidth: 30,
    openDelay: 500,
    closeDelay: 500
}

export default IconButton;