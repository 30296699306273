import moment from "moment";

export const OverTimeStatus = [
    {
        statusID: 1,
        acronym: 'A',
        status: 'Accepted',
        backgroundColor: 'transparent',
        color: 'black'
    },
    {
        statusID: 2,
        acronym: 'R',
        status: 'Rejected',
        backgroundColor: 'red',
        color: 'white'
    },
    {
        statusID: 3,
        acronym: 'B',
        status: 'Breakdown/Excused',
        backgroundColor: '#BAD7ED',
        color: 'black'
    },
    {
        statusID: 4,
        acronym: 'C',
        status: 'Manually Assigned/Compensated',
        backgroundColor: '#BAD7ED',
        color: 'black'
    }
]

function OvertimeModel(data, reportDate) { 
    this.reportTime = moment(reportDate);
    this.data = data;
    this.tableData = {
        main: [],
        sub: []
    }
    this.excelData = [];

    this.getTableData = function () {
        return this.tableData;
    }

    this.getExcelData = function () {
        return this.excelData;
    }

    this.mapMainItem = (value) => {
        let mainData = {
            DriverID: value.DriverID,
            DriverName: value.Driver,
            VehicleName: value.Vehicle,
            A: 0,
            B: 0,
            C: 0,
            R: 0
        };
      
        if (value.Overtime) {
            let A = 0, B = 0, C = 0, R = 0;
            
            value.Overtime.forEach(OT => {
                switch (OT.DriverResponse) {
                    case 1:
                        A++;
                        break;
                    case 2:
                        R++;
                        break;
                    case 3:
                        B++;
                        break;
                    case 4:
                        C++;
                        break;
                    default:
                        break;
                }
            });

            mainData.A = A;
            mainData.B = B;
            mainData.C = C;
            mainData.R = R;
        }

        return mainData;
    }

    this.mapSubItem = (driverInfo, overtimeInfo) => {
        return {
            Date: overtimeInfo.LogDate,
            VehicleName: driverInfo.Vehicle,
            DriverID: driverInfo.DriverID,
            DriverName: driverInfo.Driver,
            Status: OvertimeModel.getStatusAcronym(overtimeInfo.DriverResponse),
            StatusName: OvertimeModel.getStatusName(overtimeInfo.DriverResponse)
        }
    }

    this.mapExcelItem = (driverInfo, value) => {
        return {
            Month: driverInfo.Vehicle,
            // Driver: driverInfo.DriverName.substring(0, 20),
            Driver: driverInfo.Driver,
            ...value
        }
    }

    this.init = function () {
        let days = {}; 
        for (let index = 1; index <= this.reportTime.daysInMonth(); index++) {
            days[index] = '';
        }

        (this.data ?? []).forEach(driverInfo => {
            const mainItem = this.mapMainItem(driverInfo, true);
          
            const itemDays = { ...days };
          
            (driverInfo?.Overtime ?? []).forEach(overtimeInfo => {
                
                // mainItem[overtimeInfo.Status] = ++mainItem[overtimeInfo.Status];
                this.tableData.sub.push(this.mapSubItem(driverInfo, overtimeInfo));
                

                // itemDays[`${overtimeInfo.Date.getDate()}`] = overtimeInfo.Status;
                itemDays[`${new Date(overtimeInfo.LogDate).getDate()}`] = OvertimeModel.getStatusAcronym(overtimeInfo.DriverResponse);
            });

            this.excelData.push(this.mapExcelItem(driverInfo, itemDays));
            this.tableData.main.push(mainItem);
        });

        this.tableData.sub.sort((a, b) => moment(b.Date) - moment(a.Date));
    }

    this.init();
}

OvertimeModel.getStatusName = function (statusID) {
    const statuses = OverTimeStatus.filter(f => f.statusID === statusID);
    if (statuses.length > 0) return statuses[0].status;
    else return '';
}

OvertimeModel.getStatusAcronym = function (statusID) {
    const statuses = OverTimeStatus.filter(f => f.statusID === statusID);
    if (statuses.length > 0) return statuses[0].acronym;
    else return '';
}

OvertimeModel.getStatusColor = function (acronym) {
    const statuses = OverTimeStatus.filter(f => f.acronym === acronym);
    if (statuses.length > 0) {
        return {
            backgroundColor: statuses[0].backgroundColor,
            color: statuses[0].color
        };
    }
    else {
        return {
            backgroundColor: 'transparent',
            color: 'black'
        };
    }
}

export default OvertimeModel;