import Swal from "sweetalert2";

/**
 * Swal for showing messages
 * @param {Object} options - Swal options
 * @param {string} options.title - Swal Title
 * @param {string} options.text - Swal message
 * @param {string} options.icon - Swal icon 
 */

export function SwalMessage(options) {
    return Swal.fire({
        title: options.title,
        text: options.text,
        icon: options.icon
    });
}

/**
 * Swal for save and update request
 * @param {Object} options - Swal options
 * @param {string} options.title - Swal Title
 * @param {string} options.text - Swal message
 * @param {string} options.successTitle - Swal pop up when the request is success
 * @param {string} options.failedIcon - Swal pop up when the request is failed
 * @param {string} options.failedTitle - Swal pop up when the request is failed
 * @param {string} options.failedTitleCustom - Swal pop up when the request is failed
 * @param {callback} options.failedText - Swal pop up when the request is failed and passes error info
 * @param {callback} options.onSuccess - Callback when the request is success
 * @param {callback} options.onFailed - Callback when the request is failed
 * @param {promise} options.request - http request for updating and saving data
 */

export function SwalRequest(options) {
    Swal.fire({
        icon: 'info',
        title: options.title,
        text: options.text ?? "",
        backdrop: true,
        reverseButtons: true,
        // timer: 2000,
        // timerProgressBar: true,
        didOpen: () => {
            Swal.showLoading();
            if (navigator.onLine) {
                options.request().then(() => {
                    if (options.successTitle) {
                        Swal.fire({
                            title: options.successTitle,
                            icon: 'success'
                        }).then(() => {
                            if (options.onSuccess) {
                                Swal.close();
                                options.onSuccess();
                            }
                        });
                    }
                    else Swal.close();
                }, (e) => {
                    Swal.fire({
                        title: options?.failedTitle ? options.failedTitle : options?.failedTitleCustom(e),
                        text: options?.failedText ? options?.failedText(e) : '',
                        icon: options?.failedIcon ? options?.failedIcon(e) : 'error'
                    });
                    if (options.onFailed) options.onFailed();
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'No Internet Connection!'
                })
            }
        },
        allowOutsideClick: () => !Swal.isLoading()
    });
}

/**
 * Swal for save and update request
 * @param {Object} options - Swal options
 * @param {string} options.title - Swal Title
 * @param {string} options.text - Swal message
 * @param {string} options.successTitle - Swal pop up when the request is success
 * @param {string} options.failedTitle - Swal pop up when the request is failed
 * @param {callback} options.onSuccess - Callback when the request is success
 * @param {callback} options.onFailed - Callback when the request is failed
 * @param {promise} options.request - http request for updating and saving data
 */
export function SwalQuestion(options) {
    Swal.fire({
        title: options.title,
        // text: options.text,
        html: options.text,
        icon: 'warning',
        backdrop: true,
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: 'Yes',
        // confirmButtonColor: '#3085d6',
        // cancelButtonColor: '#d33',
        // confirmButtonText: 'Yes, delete it!'
        width: '600px',
        showLoaderOnConfirm: true,
        preConfirm: () => {
            return options.request().then((response) => {
                if (options.successTitle) {
                    Swal.fire({
                        title: options.successTitle,
                        icon: 'success'
                    }).then(() => {
                        if (options.onSuccess) {
                            Swal.close();
                            options.onSuccess(response.data);
                        }
                    });
                }
            }, (e) => {
                Swal.fire({
                    title: options.failedTitle,
                    text: options?.failedText ? options?.failedText(e) : '',
                    icon: 'error'
                });
                if (options.onFailed) options.onFailed();
            });
        },
        allowOutsideClick: () => !Swal.isLoading()
    });
}