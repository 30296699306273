import React from "react"
import { GridComponent, ColumnDirective, ColumnsDirective, ExcelExport, Filter, Inject, Page, Sort, Toolbar, DetailRow } from "@syncfusion/ej2-react-grids";
import useIsMobile from "../../../../Utils/UseIsMobile";

const VehicleServiceTable = React.forwardRef((props, ref) => {
    const { isMobile } = useIsMobile();
    const treegrid = React.useRef();
    const [dataSource, setDataSource] = React.useState([]);

    React.useImperativeHandle(ref, () => {
        return {
            setDataSource(data) {
                setDataSource(data.map(value => {
                    return {
                        ...value,
                        current_gps_mileage: '-',
                        service_alert_date: value?.service_alert_date ?? '-',
                        service_alert_mileage: value?.service_alert_mileage ?? '-',
                        service_remarks: value?.service_remarks ?? '-',
                        service_cost: value?.service_cost ?? '-',
                        service_status: value.service_status === 1 ? 'Open' : 'Close'
                    }
                }));
            }
        }
    });

    const toolbarClick = (args) => {
        if (treegrid && args.item.text === 'Excel Export') {
            props.onExcelExport();
        }
    };

    return (
        <GridComponent
            ref={treegrid}
            dataSource={dataSource}
            enableAdaptiveUI={isMobile}
            toolbar={['ExcelExport', 'Search']}
            toolbarClick={toolbarClick}
            allowResizing={true}
            allowExcelExport={true}
            allowPaging={true}
            allowFiltering={true}
            allowSorting={true}
            allowTextWrap={true} 
            textWrapSettings={{ wrapMode: 'Content' }}
            filterSettings={{ type: 'Excel' }}
            sortSettings={{ columns: [{ field: 'Date', direction: 'Descending' }] }}
            pageSettings={{ pageSize: 20 }}>
            <ColumnsDirective>
                <ColumnDirective field='service_from_time' headerText='From' type="date" format='dd-MMM-yyyy' />
                <ColumnDirective field='service_to_time' headerText='To' type="date" format='dd-MMM-yyyy' />
                <ColumnDirective field='service_vehicle'  headerText='Vehicle' />
                <ColumnDirective field='service_type_name' headerText='Type' />
                <ColumnDirective width={200} field='current_location_name' headerText='Current Location' />
                <ColumnDirective field='service_garage_name' headerText='Garage/Contractor' />
                <ColumnDirective field='service_alert_date' headerText='Alert Me' type="date" format='dd-MMM-yyyy' />
                <ColumnDirective width={230} field='current_gps_mileage' headerText='Current GPS mileage (km)' />
                <ColumnDirective width={230} field='service_alert_mileage' headerText='Alert Me (Mileage, Km)' />
                <ColumnDirective field='service_status' headerText='Status' />
                <ColumnDirective field='service_remarks' headerText='Remark' />
                <ColumnDirective field='service_cost' headerText='Cost $' />
                <ColumnDirective field='service_to_time' headerText='Report Date' type="date" format='dd-MMM-yyyy' />
            </ColumnsDirective>
            <Inject services={[Filter, Sort, Toolbar, ExcelExport, Page, DetailRow]} />
        </GridComponent>
    );
});

export default VehicleServiceTable;