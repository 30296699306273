import React from "react"
import { GridComponent, ColumnDirective, ColumnsDirective, ExcelExport, Filter, Inject, Page, Sort, Toolbar } from "@syncfusion/ej2-react-grids";
import useIsMobile from "../../../../Utils/UseIsMobile";
import { OverTimeStatus } from "../Models/OvertimeModel";

const OvertimeTable = React.forwardRef((props, ref) => {
    const { isMobile } = useIsMobile();
    const treegrid = React.useRef();
    const [dataSource, setDataSource] = React.useState({
        main: [],
        sub: []
    });

    React.useImperativeHandle(ref, () => {
        return {
            setDataSource(data) {
                setDataSource(data);
            }
        }
    });

    const toolbarClick = (args) => {
        console.log(args.item.text);
        if (treegrid && args.item.text === 'Excel Export') {
            props?.onExcelExport();
        }
    };

    const childGridOptions = {
        columns: [
            { field: 'Date', headerText: 'Date', width:110, textAlign: 'left',  type:"date", format:'cccc, MMM d, yyyy'},
            { field: 'VehicleName', headerText: 'Vehicle', width:120, textAlign: 'left'},
            { field: 'DriverName', headerText: 'Driver', width:100, textAlign: 'left'},
            { field: 'StatusName', headerText: 'Status', width:100, textAlign: 'left'},
        ],
        dataSource: dataSource.sub,
        queryString: 'DriverID'
    }

    return (
        <GridComponent
            ref={treegrid}
            dataSource={dataSource.main}
            enableAdaptiveUI={isMobile}
            allowTextWrap={true}
            toolbar={['ExcelExport', 'Search']}
            toolbarClick={toolbarClick}
            allowExcelExport={true}
            allowPaging={true}
            allowFiltering={true}
            allowSorting={true}
            childGrid={childGridOptions}
            filterSettings={{ type: 'Excel' }}
            sortSettings={{ columns: [{ field: 'Date', direction: 'Descending' }] }}
            pageSettings={{ pageSize: 20 }}>
            <ColumnsDirective>
                {/* <ColumnDirective field='Date' width={110} headerText='Date' type="date" format='d-MMM' /> */}
                <ColumnDirective field='VehicleName' width={120} headerText='Vehicle' />
                <ColumnDirective field='DriverName' width={120} headerText='Driver' />
                <ColumnDirective field={OverTimeStatus[0].acronym} width={100} headerText={OverTimeStatus[0].status} />
                <ColumnDirective field={OverTimeStatus[1].acronym} width={100} headerText={OverTimeStatus[1].status} />
                <ColumnDirective field={OverTimeStatus[2].acronym} width={100} headerText={OverTimeStatus[2].status} />
                <ColumnDirective field={OverTimeStatus[3].acronym} width={100} headerText={OverTimeStatus[3].status} />
            </ColumnsDirective>
            <Inject services={[Filter, Sort, Toolbar, ExcelExport, Page]} />
        </GridComponent>
    );
});

export default OvertimeTable;