import React from "react";
import { SpreadsheetComponent, SheetsDirective, SheetDirective, CellsDirective, CellDirective, RowDirective, RowsDirective, ColumnsDirective, ColumnDirective } from "@syncfusion/ej2-react-spreadsheet";
import { SwalRequest } from "../../../../Utils/Swal";
import { HttpAxiosServers } from "../../../../Utils/AxiosHttp";
import moment from "moment";
import OvertimeModel from "../Models/OvertimeModel";
const OvertimeExcel = React.forwardRef((props, ref) => {
    // const currentDate = moment();
    const days = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
    const spreadsheetRef = React.useRef();
    const [excelData, setExcelData] = React.useState({
        fileName: 'Overtime',
        otType: '',
        dataSource: []
    });
    // const alreadyRendered = React.useRef(false);
    React.useImperativeHandle(ref, () => {
        return {
            setExcelData(data) {
                setExcelData(data);
            },
            export() {
                exportExcel();
            }
        }
    });

    // const createdHandler = () => {
    //     if (!alreadyRendered.current) {
    //         console.log('createdHandler');
    //         alreadyRendered.current = true;
    //         spreadsheetRef.current.insertRow();

    //         let firstRowIndex = 0;

    //         spreadsheetRef.current.updateCell({
    //             value: 'OT WeekDay',
    //             style: { border: '1px solid #000000' }
    //         }, getCellAddress(0, firstRowIndex++));

    //         spreadsheetRef.current.updateCell({
    //             style: { border: '1px solid #000000' }
    //         }, getCellAddress(0, firstRowIndex++));
    //         spreadsheetRef.current.setColWidth(130, 0);
    //         spreadsheetRef.current.setColWidth(180, 1);

    //         const daysInMonth = Array.from(Array(currentDate.daysInMonth()), (_, i) => i + 1);
    //         daysInMonth.forEach(day => {
    //             const weekDay = moment(currentDate).set('date', day).weekday();
    //             spreadsheetRef.current.setColWidth(40, firstRowIndex);
    //             spreadsheetRef.current.updateCell({
    //                 value: days[weekDay],
    //                 style: {
    //                     border: '1px solid #000000',
    //                     backgroundColor: weekDay === 0 ? '#FFF2CE' : 'transparent',
    //                     textAlign: 'center'
    //                 }
    //             }, getCellAddress(0, firstRowIndex++));
    //         });

    //         for (let i = 2; i <= excelData.dataSource.length + 1; i++) {
    //             for (let j = 0; j < currentDate.daysInMonth() + 2; j++) {
    //                 if (i === 2) {
    //                     spreadsheetRef.current.updateCell({
    //                         style: {
    //                             border: '1px solid #000000'
    //                         }
    //                     }, `${letters[j]}1`);
    //                 }

    //                 const address = `${letters[j]}${i + 1}`;

    //                 spreadsheetRef.current.updateCell({
    //                     style: {
    //                         backgroundColor: i % 2 === 0 ? '#d3d3d3' : '',
    //                         border: '1px solid #000000',
    //                         textAlign: letters[j] === 'a' || letters[j] === 'b' ? 'left' : 'center',
    //                     }
    //                 }, address);
    //             }
    //         }

    //         spreadsheetRef.current.updateCell({
    //             style: {
    //                 // ...itemColor,
    //                 fontWeight: 'bold',
    //                 border: '1px solid #000000'
    //             }
    //         }, 'A2');

    //         spreadsheetRef.current.updateCell({
    //             style: {
    //                 // ...itemColor,
    //                 fontWeight: 'bold',
    //                 border: '1px solid #000000'
    //             }
    //         }, 'B2');
    //     }
    // }

    const exportExcel = () => {
        SwalRequest({
            title: 'Exporting Excel File',
            text: 'Please wait...',
            request: async () => new Promise((resolve, reject) => {
                spreadsheetRef.current.saveAsJson().then(response => {
                    const fileName = excelData.fileName;
                    const extension = 'Xlsx';
                    var formData = new FormData();
                    formData.append('JSONData', JSON.stringify(response.jsonObject.Workbook));
                    formData.append('FileName', fileName);
                    formData.append('SaveType', extension);
                    formData.append('FileContentType', extension);
                    formData.append('VersionType', extension);
                    formData.append('PDFLayoutSettings', JSON.stringify({ "fitSheetOnOnePage": false }));
                    fetch(`${HttpAxiosServers.kimhock}/Report/ExtractReport`, {
                        method: 'POST',
                        body: formData
                    }).then(response => {
                        response.blob().then(data => {
                            const url = URL.createObjectURL(new Blob([data], { type: 'application/octet-stream' }));
                            const anchor = document.createElement('a');
                            anchor.href = url;
                            anchor.setAttribute('download', `${fileName}.${extension}`);
                            document.body.appendChild(anchor);
                            anchor.click();
                            anchor.remove();
                            resolve();
                        }, () => reject('Failed to generate file.'));
                    }, () => reject('Failed to Extract Report.'));
                }, () => reject('Failed to saveAsJSON.'));
            }),
            successTitle: 'Export is successful!',
            failedTitle: 'Unable to export excel file',
            failedText: (error) => error
        });
    }
   
    return (
        excelData.dataSource.length > 0 && <div style={{display: 'none'}}>
            <SpreadsheetComponent
                ref={spreadsheetRef}
            // created={createdHandler}
            // queryCellInfo={(e) => {
            //     const statuses = OverTimeStatus.map((value => value.acronym));

            //     if (statuses.includes(e.cell?.value)) {
            //         const itemColor = OvertimeModel.getStatusColor(e.cell?.value);

            //         console.log(e.cell);
            //         spreadsheetRef.current.updateCell({
            //             style: {
            //                 ...itemColor,
            //                 backgroundColor: e.cell.style.backgroundColor === "#d3d3d3" &&
            //                     itemColor.backgroundColor === 'transparent' ? "#d3d3d3" :
            //                     (e.cell.style.backgroundColor === '#FFF2CE' && e.cell?.value === 'A') ? '#FFF2CE' : itemColor.backgroundColor,
            //                 textAlign: 'center',
            //                 border: '1px solid #000000'
            //             }
            //         }, e.address);
            //     }


            //     if (e.cell?.value && (e.cell?.value.includes('\xA0'))) {
            //         spreadsheetRef.current.updateCell({
            //             value: e.cell.value,
            //             style: {
            //                 // ...itemColor,
            //                 fontWeight: 'bold',
            //                 textAlign: 'center',
            //                 border: '1px solid #000000'
            //             }
            //         }, e.address);

            //         const weekDay = moment(currentDate).set('date', e.cell.value).weekday();
            //         if (weekDay === 0) {
            //             const colAddress = e.address.replace(/[0-9]/g, '');
            //             for (let i = 1; i <= excelData.dataSource.length + 2; i++) {
            //                 spreadsheetRef.current.updateCell({
            //                     style: {
            //                         backgroundColor: '#FFF2CE',
            //                         textAlign: 'center',
            //                         border: '1px solid #000000'
            //                     }
            //                 }, `$${colAddress}${i}`);
            //             }
            //         }

            //         console.log('test');
            //         if (parseInt(e.cell.value.substring(1)) === currentDate.daysInMonth()) {
            //             console.log('tes2t');
            //         }
            //     }
            // }}
            >
                <SheetsDirective>
                    <SheetDirective>
                        <RowsDirective>
                            <RowDirective>
                                <CellsDirective>
                                    <CellDirective style={{ border: '1px solid #000000' }} value={excelData.otType}></CellDirective>
                                    <CellDirective style={{ border: '1px solid #000000' }} value=''></CellDirective>
                                    {
                                        (() => {
                                            const weeks = [];
                                            // for (let i = 1; i <= currentDate.daysInMonth(); i++) {
                                            for (let i = 1; i <= moment(excelData.reportDate).daysInMonth(); i++) {
                                                // const weekDay = moment(currentDate).set('date', i).weekday();
                                                const weekDay = moment(excelData.reportDate).set('date', i).weekday();
                                                const isSunday = weekDay === 0;
                                                weeks.push(
                                                    <CellDirective key={i} style={{ backgroundColor: isSunday ? '#FFF2CE' : null, textAlign: 'center', border: '1px solid #000000' }} value={days[weekDay]}></CellDirective>
                                                );
                                            }
                                            return weeks;
                                        })()
                                    }
                                </CellsDirective>
                            </RowDirective>
                            <RowDirective>
                                <CellsDirective>
                                    <CellDirective style={{ fontWeight: 'bold', border: '1px solid #000000' }} value={`Month of ${moment(excelData.reportDate).format('MMM YYYY')}`}></CellDirective>
                                    <CellDirective style={{ fontWeight: 'bold', border: '1px solid #000000' }} value='Driver'></CellDirective>
                                    {
                                        (() => {
                                            const weeks = [];
                                            // for (let i = 1; i <= currentDate.daysInMonth(); i++) {
                                            for (let i = 1; i <= moment(excelData.reportDate).daysInMonth(); i++) {
                                                // const isSunday = moment(currentDate).set('date', i).weekday() === 0;
                                                const isSunday = moment(excelData.reportDate).set('date', i).weekday() === 0;
                                                weeks.push(
                                                    <CellDirective key={i} style={{ backgroundColor: isSunday ? '#FFF2CE' : null, fontWeight: 'bold', textAlign: 'center', border: '1px solid #000000' }} value={i}></CellDirective>
                                                );
                                            }
                                            return weeks;
                                        })()
                                    }
                                </CellsDirective>
                            </RowDirective>
                            {
                                excelData.dataSource.map((value, i) => {
                                    const data = value;
                                    const items = [];
                                    // let alternateBG = i % 2 === 0 ? '#d3d3d3' : null;
                                    let alternateBG = null;
                                    items.push(
                                        <CellDirective key={`VehicleID${i}`} style={{ border: '1px solid #000000' }} value={data.Month}></CellDirective>
                                    );
                                    items.push(
                                        <CellDirective key={`DriverName${i}`} style={{ border: '1px solid #000000' }} value={data.Driver}></CellDirective>
                                    );
                                    // for (let j = 1; j <= currentDate.daysInMonth(); j++) {
                                    for (let j = 1; j <= moment(excelData.reportDate).daysInMonth(); j++) {
                                        // const isSunday = moment(currentDate).set('date', j).weekday() === 0;
                                        const isSunday = moment(excelData.reportDate).set('date', j).weekday() === 0;
                                        const itemColor = OvertimeModel.getStatusColor(data[j]);
                                        const isNormBG = data[j] === 'A' || data[j] === '';
                                        // alternateBG = i % 2 === 0 && !isSunday ? '#d3d3d3' : isSunday ? '#FFF2CE' : null;
                                        alternateBG = isSunday ? '#FFF2CE' : null;
                                        items.push(
                                            <CellDirective key={i} style={{ backgroundColor: isSunday && isNormBG ? alternateBG : isNormBG ? alternateBG : itemColor.backgroundColor, color: itemColor.color, textAlign: 'center', border: '1px solid #000000' }} value={data[j]}></CellDirective>
                                        );
                                    }

                                    return <RowDirective key={i}><CellsDirective> {items} </CellsDirective> </RowDirective>
                                })
                            }
                        </RowsDirective>
                        <ColumnsDirective>
                            {
                                (() => {
                                    const columns = [];
                                    // for (let i = 0; i < currentDate.daysInMonth() + 2; i++) {
                                    for (let i = 0; i < moment(excelData.reportDate).daysInMonth() + 2; i++) {
                                        const isDays = i > 1;
                                        const isDriversCol = i === 1;
                                        columns.push(
                                            <ColumnDirective key={i} width={isDays ? 35 : isDriversCol ? 200 : 130}></ColumnDirective>
                                        );
                                    }
                                    return columns;
                                })()
                            }
                        </ColumnsDirective>
                    </SheetDirective>
                </SheetsDirective>
            </SpreadsheetComponent>
        </div>
    )
});
export default OvertimeExcel;