import * as Sentry from "@sentry/browser";

const SentryCategories = {
    click: 'ui.click',
    input: 'ui.input',
    request: 'xhr',
    requestPayload: 'payload',
    operation: 'operation',
    error: 'Error Additional Info'
}

const SentryTypes = {
    user: 'user', //User Action
    http: 'http', //Http Request
    debug: 'debug', //Debug info/console
    info: 'info',
    error: 'error',
}

const SentryLevels = {
    fatal: 'fatal',
    critical: 'critical',
    error: 'error',
    warning: 'warning',
    log: 'log',
    info: 'info',
    debug: 'debug'
}

function SentryBreadcrumb() { }

SentryBreadcrumb.operation = function({message, data}) {
    Sentry.addBreadcrumb({
        type: SentryTypes.info,
        level: SentryLevels.info,
        category: SentryCategories.operation,
        message: message ?? "",
        data: data
    });
}

SentryBreadcrumb.click = function({message, data}) {
    Sentry.addBreadcrumb({
        type: SentryTypes.user,
        level: SentryLevels.info,
        category: SentryCategories.click,
        message: message ?? "",
        data: data
    });
}

SentryBreadcrumb.requestPayload = function({message, data}) {
    Sentry.addBreadcrumb({
        type: SentryTypes.debug,
        level: SentryLevels.info,
        category: SentryCategories.requestPayload,
        message: message ?? "",
        data: data
    });
}

SentryBreadcrumb.error = function({message, data, error}) {
    Sentry.addBreadcrumb({
        type: SentryTypes.debug,
        level: SentryLevels.info,
        category: SentryCategories.error,
        message: message ?? "",
        data: data
    });

    if (error) {
        try {
            if (!(error instanceof Error)) {
                error = new Error(`Non-Error rejection: ${JSON.stringify(error)}`);
            }

            Sentry.captureException(error);
        } catch (e) {
            Sentry.captureException(e);
        }
    }
}

SentryBreadcrumb.categories = {
    operation: 'Operation',
    requestPayload: 'RequestPayload',
    tryCatch: 'TryCatch',
    validation: 'Validation'
}

export {
    SentryBreadcrumb,
    SentryCategories,
    SentryTypes
}