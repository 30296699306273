// import { Route, Routes } from 'react-router-dom';
import PackageJson from '../package.json';

import { lazy, Suspense, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import { ComponentSpinner } from './Components/Loader';
import DriverOrder from './Container/Report/DriverOrder';
import Overtime from './Container/Report/Overtime';
import VehicleService from './Container/Report/VehicleService';
import PageNotFound from './Container/404';
import EmbedContainer from './Components/EmbedContainer';
import AthenaSettings, { AthenaSettingsKeys } from './Utils/AthenaSettings';
// import Crp from './Container/Crp/index';

const AuthWrapper = lazy(() => import('./Components/AuthWrapper'));
const HomePage = lazy(() => import('./Container'));
const Scheduler = lazy(() => import('./Container/Scheduler'));
const RecurringJobs = lazy(() => import('./Container/RecurringJobs'));
const InternalDispatch = lazy(() => import('./Container/InternalDispatch'));
const InternalDispatchOld = lazy(() => import('./Container/InternalDispatchOld'));
const Customers = lazy(() => import('./Container/Customers'));
const Drivers = lazy(() => import('./Container/Drivers'));
const Report = lazy(() => import('./Container/Report'));
const VMR = lazy(() => import('./Container/Report/VMR'));
const ServiceMaintenance = lazy(() => import('./Container/ServiceMaintenance'));
const TripRate = lazy(() => import('./Container/Report/TripRate'));
const Crp = lazy(() => import('./Container/Crp'));

const LazyVMR = () => (
  <Suspense fallback={<ComponentSpinner wholePage={true} />}>
    <EmbedContainer>
      <VMR isEmbed={true} />
    </EmbedContainer>
  </Suspense>
);

const LazyService = () => (
  <Suspense fallback={<ComponentSpinner wholePage={true} />}>
    <EmbedContainer>
      <ServiceMaintenance isEmbed={true} />
    </EmbedContainer>
  </Suspense>
);

const LazyTripRate = () => (
  <Suspense fallback={<ComponentSpinner wholePage={true} />}>
    <EmbedContainer>
      <TripRate isEmbed={true} />
    </EmbedContainer>
  </Suspense>
);

const LazyDriverOrder = () => (
  <Suspense fallback={<ComponentSpinner wholePage={true} />}>
    <EmbedContainer>
      <DriverOrder isEmbed={true} />
    </EmbedContainer>
  </Suspense>
);

const LazyOTWeekdays = () => (
  <Suspense fallback={<ComponentSpinner wholePage={true} />}>
    <EmbedContainer>
      <Overtime isEmbed={true} isWeekdays={true} />
    </EmbedContainer>
  </Suspense>
);

const LazyOTSunPH = () => (
  <Suspense fallback={<ComponentSpinner wholePage={true} />}>
    <EmbedContainer>
      <Overtime isEmbed={true} isWeekdays={false} />
    </EmbedContainer>
  </Suspense>
);

const LazyVehicleService = () => (
  <Suspense fallback={<ComponentSpinner wholePage={true} />}>
    <EmbedContainer>
      <VehicleService isEmbed={true} />
    </EmbedContainer>
  </Suspense>
);

const LazyRecurringJobs = () => (
  <Suspense fallback={<ComponentSpinner wholePage={true} />}>
    <EmbedContainer>
      <RecurringJobs isEmbed={true} />
    </EmbedContainer>
  </Suspense>
)

const LazyInternalDispatch = () => (
  <Suspense fallback={<ComponentSpinner wholePage={true} />}>
    <EmbedContainer>
      <InternalDispatch isEmbed={true} />
    </EmbedContainer>
  </Suspense>
)

const LazyInternalDispatchOld = () => (
  <Suspense fallback={<ComponentSpinner wholePage={true} />}>
    <EmbedContainer>
      <InternalDispatchOld isEmbed={true} />
    </EmbedContainer>
  </Suspense>
)

const LazyCrp = () => (
  <Suspense fallback={<ComponentSpinner wholePage={true} />}>
    <EmbedContainer>
      <Crp />
    </EmbedContainer>
  </Suspense>
)

function App() {
  useEffect(() => {
    checkCache();
  }, []);

  const checkCache = () => {
    const settings = new AthenaSettings();
    const hasVersion = localStorage.getItem('version') !== null;
    if (settings.value?.version !== PackageJson.version) {
      if ('caches' in window) {
        caches.keys().then((names) => {
          names.forEach(name => {
            caches.delete(name);
          });
        });

        window.location.reload(true);
      }

      settings.setItem(AthenaSettingsKeys.version, PackageJson.version);
      if (hasVersion) localStorage.removeItem('version');
    }
  };

  return (
    <>
      <Routes>
        <Route path="/" exact element={(
          <Suspense fallback={<ComponentSpinner wholePage={true} />}>
            <HomePage />
          </Suspense>
        )}>
          {/* <Route path="dashboard" element={<AuthWrapper component={Dashboard} />} /> */}
          <Route path="scheduler" element={<AuthWrapper component={Scheduler} />} />
          <Route path="customer" element={<AuthWrapper component={Customers} />} />
          <Route path="recurringjobs" element={<AuthWrapper component={RecurringJobs} />} />
          <Route path="internaldespatch" element={<AuthWrapper component={InternalDispatch} />} />
          {/* <Route path="zone" element={<AuthWrapper component={Zones} />} /> */}
          <Route path="driver" element={<AuthWrapper component={Drivers} />} />
          {/* <Route path="vehicle" element={<AuthWrapper component={Vehicles} />} /> */}
          <Route path="report" element={<AuthWrapper component={() => <Report />} />} />
          <Route path="report/vmr" element={<AuthWrapper component={() => <Report type="VMR" />} />} />
          <Route path="report/tripcalculation" element={<AuthWrapper component={() => <Report type="TripCalculation" />} />} />
          <Route path="report/driverorder" element={<AuthWrapper component={() => <Report type="DriverOrder" />} />} />
          <Route path="report/overtime/weekdays" element={<AuthWrapper component={() => <Report type="OvertimeWeekdays" />} />} />
          <Route path="report/overtime/holiday" element={<AuthWrapper component={() => <Report type="OvertimeHoliday" />} />} />
          <Route path="report/vehicleservice" element={<AuthWrapper component={() => <Report type="VehicleService" />} />} />
          <Route path="maintenance" element={<AuthWrapper component={ServiceMaintenance} />} />
          <Route path="triprate" element={<AuthWrapper component={TripRate} />} />
        </Route>
        <Route path="/tripcalculation/:token" element={<LazyTripRate />}>
          {/* optional theme (default: light) darkMode: true, 1 or dark */}
          <Route path=":theme" element={<LazyTripRate />} />
          <Route path="" element={<LazyTripRate />} />
        </Route>
        <Route path="/report/:token" element={<LazyVMR />}>
          {/* optional theme (default: light) darkMode: true, 1 or dark */}
          <Route path=":theme" element={<LazyVMR />} />
          <Route path="" element={<LazyVMR />} />
        </Route>
        <Route path="/maintenance/:token" element={<LazyService />}>
          {/* optional theme (default: light) darkMode: true, 1 or dark */}
          <Route path=":theme" element={<LazyService />} />
          <Route path="" element={<LazyService />} />
        </Route>
        <Route path="/average/:token" element={<LazyTripRate />}>
          {/* optional theme (default: light) darkMode: true, 1 or dark */}
          <Route path=":theme" element={<LazyTripRate />} />
          <Route path="" element={<LazyTripRate />} />
        </Route>
        <Route path="/driverorder/:token" element={<LazyDriverOrder />}>
          {/* optional theme (default: light) darkMode: true, 1 or dark */}
          <Route path=":theme" element={<LazyDriverOrder />} />
          <Route path="" element={<LazyDriverOrder />} />
        </Route>
        <Route path="/otweekdays/:token" element={<LazyOTWeekdays />}>
          {/* optional theme (default: light) darkMode: true, 1 or dark */}
          <Route path=":theme" element={<LazyOTWeekdays />} />
          <Route path="" element={<LazyOTWeekdays />} />
        </Route>
        <Route path="/otsunph/:token" element={<LazyOTSunPH />}>
          {/* optional theme (default: light) darkMode: true, 1 or dark */}
          <Route path=":theme" element={<LazyOTSunPH />} />
          <Route path="" element={<LazyOTSunPH />} />
        </Route>
        <Route path="/vehicleservice/:token" element={<LazyVehicleService />}>
          {/* optional theme (default: light) darkMode: true, 1 or dark */}
          <Route path=":theme" element={<LazyVehicleService />} />
          <Route path="" element={<LazyVehicleService />} />
        </Route>
        <Route path="/crp/:token" element={<LazyCrp />}>
          {/* optional theme (default: light) darkMode: true, 1 or dark */}
          <Route path=":theme" element={<LazyCrp />} />
          <Route path="" element={<LazyCrp />} />
        </Route>
        <Route path="/scheduler/recurringjobs/:token" element={<LazyRecurringJobs />}>
          {/* optional theme (default: light) darkMode: true, 1 or dark */}
          <Route path=":theme" element={<LazyRecurringJobs />} />
          <Route path="" element={<LazyRecurringJobs />} />
        </Route>
        <Route path="/scheduler/internaldispatch/:token" element={<LazyInternalDispatchOld />}>
          {/* optional theme (default: light) darkMode: true, 1 or dark */}
          <Route path=":theme" element={<LazyInternalDispatchOld />} />
          <Route path="" element={<LazyInternalDispatchOld />} />
        </Route>
        <Route path="/scheduler/internaldespatch/:token" element={<LazyInternalDispatch />}>
          {/* optional theme (default: light) darkMode: true, 1 or dark */}
          <Route path=":theme" element={<LazyInternalDispatch />} />
          <Route path="" element={<LazyInternalDispatch />} />
        </Route>
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </>
  );
}

// function App() {
//   return <SideBar />
// }

export default App;