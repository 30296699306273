import { AccordionComponent, AccordionItemDirective, AccordionItemsDirective } from '@syncfusion/ej2-react-navigations';
import * as React from 'react';
import { Grid } from "@mui/material";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import { ComboBoxComponent } from '@syncfusion/ej2-react-dropdowns';
import { Controller, useForm } from 'react-hook-form';
// import HttpAxios from '../../../../Utils/AxiosHttp';
// import { SwalRequest } from '../../../../Utils/Swal';
import OvertimeFilterModel from '../Models/OvertimeFilterModel';

const overtimeStatus = [{
    value: 1,
    text: 'Accepted'
},{
    value: 2,
    text: 'Rejected'
},{
    value: 3,
    text: 'Breakdown/Excused'
},{
    value: 4,
    text: 'Manually Assigned/Compensated'
}]

const OvertimeFilterContent = React.forwardRef((props, ref) => {
    // const httpAxios = React.useRef(new HttpAxios());
    const [dataSource, setDataSource] = React.useState({
        vehicles: [],
        drivers: [],
        status: overtimeStatus
    });

    // eslint-disable-next-line
    const { control, reset, handleSubmit } = useForm({
        defaultValues: {
            WorkTime: null,
            Date: new Date(),
            VehicleID: null,
            DriverID: null,
            Status: null
        }
    });

    React.useImperativeHandle(ref, () => {
        return {
            UpdateFilter(filterData) {
                reset(filterData);
            },
            setDataSource(data) {
                setDataSource({
                    ...dataSource,
                    ...data
                });
            }
        }
        // eslint-disable-next-line
    }, []);

    React.useEffect(() => {
        if (props?.filter) {
            setTimeout(() => {
                reset(props.filter);
            }, 500);
        }
        // eslint-disable-next-line
    }, []);

    const onSubmit = (values) => {
        props.filterOnClick(values);
    }

    return (
        <div className="e-card" id="basic_card" style={{ width: '100%' }}>
            <div className="e-card-content" style={{ padding: '5px' }}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={2}>
                        <Grid item lg={3} md={3} sm={12} xs={12}>
                            <Controller
                                name="Date"
                                control={control}
                                render={({ field }) => (
                                    <DatePickerComponent
                                        {...field}
                                        allowEdit={false}
                                        floatLabelType='Auto'
                                        showClearButton={false}
                                        format="MMMM y" 
                                        start="Year" 
                                        depth="Year"
                                        id="startDatepicker"
                                        placeholder="Month"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item lg={3} md={3} sm={12} xs={12}>
                            <Controller
                                name="VehicleID"
                                control={control}
                                render={({ field }) => (
                                    <ComboBoxComponent
                                        {...field}
                                        id="vehicle"
                                        floatLabelType='Auto'
                                        dataSource={dataSource.vehicles}
                                        autofill={true}
                                        placeholder="Vehicle"
                                        // fields={{ text: 'Name', value: 'VehicleID' }} />
                                        fields={{ text: 'Name', value: 'Name' }} />
                                )}
                            />
                        </Grid>
                        <Grid item lg={3} md={3} sm={12} xs={12}>
                            <Controller
                                name="DriverID"
                                control={control}
                                render={({ field }) => (
                                    <ComboBoxComponent
                                        {...field}
                                        id="driverID"
                                        floatLabelType='Auto'
                                        dataSource={dataSource.drivers}
                                        autofill={true}
                                        placeholder="Driver"
                                        fields={{ text: 'Name', value: 'DriverID' }} />
                                )}
                            />
                        </Grid>
                        <Grid item lg={3} md={3} sm={12} xs={12}>
                            <Controller
                                name="Status"
                                control={control}
                                render={({ field }) => (
                                    <ComboBoxComponent
                                        {...field}
                                        id="status"
                                        floatLabelType='Auto'
                                        dataSource={dataSource.status}
                                        autofill={true}
                                        placeholder="Status"
                                        fields={{ text: 'text', value: 'value' }} />
                                )}
                            />
                        </Grid>
                        <Grid className="center-content" item xs={12}>
                            <button className="e-btn e-outline e-warning btn-custom-width" onClick={props.onClear} style={{ marginBottom: '5px', marginTop: '10px', marginRight: '8px' }}>
                                Clear
                            </button>
                            <button type='submit' className="e-btn e-outline e-success btn-custom-width" style={{ marginBottom: '5px', marginTop: '10px' }}>
                                Filter Report
                            </button>
                        </Grid>
                    </Grid>
                </form>
            </div>
        </div>
    );
});

const OvertimeFilter = React.forwardRef((props, ref) => {
    // const httpAxios = props.axios;
    const filter = React.useRef(new OvertimeFilterModel());
    const filterData = React.useRef({
        vehicles: [],
        drivers: []
    });
    const [headerTitle, setHeaderTitle] = React.useState("Filter");
    const [isExpanded, setIsExpanded] = React.useState(true);
    const overtimeFilterRef = React.useRef(null);

    React.useImperativeHandle(ref, () => {
        return {
            setDataSource(data) {
                filterData.current = {
                    ...filterData.current,
                    ...data
                };
                overtimeFilterRef.current.setDataSource(filterData.current);
            }
        }
        // eslint-disable-next-line
    }, []);

    // React.useEffect(() => {
    //     httpAxios.post(`zone/scrap%20yard?Token=${httpAxios.token}`).then((response) => {
    //         setPlantCodes(response);
    //     });
    //     // eslint-disable-next-line
    // }, []);

    const expandAccordion = (event) => {
        setIsExpanded(event.isExpanded);
        setHeaderTitle(event.isExpanded ? "Filter" : filter.current.getFilterHeader());
        //prevent UI stutters
        setTimeout(() => {
            overtimeFilterRef.current.setDataSource(filterData.current);
            overtimeFilterRef.current.UpdateFilter(filter.current.filter);
        }, 300);
    }

    const filterHandler = (filterData) => {
        // accordionRef.expandItem(false);
        filterData = new OvertimeFilterModel(filterData);
        filter.current = filterData;
        props.onFilter(filterData.getFilter());
    };

    const clearFilterHandler = () => {
        filter.current = new OvertimeFilterModel();
        overtimeFilterRef.current.UpdateFilter(filter.current.filter);
    };

    const animation = { expand: { effect: '', duration: 0, easing: '' }, collapse: { effect: '', duration: 0, easing: '' } };
    return (
        <>
            <AccordionComponent
                expanded={expandAccordion} animation={animation} expandMode='Single'
            >
                <AccordionItemsDirective>
                    <AccordionItemDirective id='filter-acrdn' expanded={isExpanded} header={headerTitle} content={() => (
                        <OvertimeFilterContent
                            ref={overtimeFilterRef}
                            dataSource={props.dataSource}
                            onClear={clearFilterHandler}
                            filterOnClick={filterHandler}
                        />
                    )} />
                </AccordionItemsDirective>
            </AccordionComponent>
        </>
    );
});

export default React.memo(OvertimeFilter);