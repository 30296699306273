import { useEffect, useRef, useState } from 'react';
import ThemeSwitch from './ThemeSwitch';
import { SidebarComponent } from '@syncfusion/ej2-react-navigations';
import LogoutIcon from '@mui/icons-material/Logout';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import TodayIcon from '@mui/icons-material/Today';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
// import LocationOnIcon from '@mui/icons-material/LocationOn';
import AlbumIcon from '@mui/icons-material/Album';
// import LocalShippingIcon from '@mui/icons-material/LocalShipping';
// import TripOrigin from '@mui/icons-material/TripOrigin';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Grid, Typography } from '@mui/material';
import ThemeSwitcher from '../Utils/ThemeSwitcher';
import { Build, Menu, Summarize } from '@mui/icons-material';
import IconButton from './IconButton';
import { ThemeLoader } from './Loader';
import useIsMobile from '../Utils/UseIsMobile';
import AthenaSettings from '../Utils/AthenaSettings';

export function Header(props) {
  const boxStyle = props?.boxStyle ?? {};
  return (
    <Box className='custom-sizing' style={props?.isEmbed ? { borderBottom: 'none', ...boxStyle } : boxStyle}>
      <Grid container spacing={1}>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          {!props?.isEmbed && <Typography
            className='header-title'
            variant="h4"
            noWrap
            component="div"
            style={{fontSize: '28px', fontWeight: '400', color: '#463738'}}
          >
            {props.title}
          </Typography>}
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12} display={'flex'} alignItems={'center'} justifyContent={'flex-end'}>
          <Box className='header-btn-container'>
            {/* <Button variant="outlined" onClick={props.actionOnClick}>New Order</Button> */}
            {props.buttons && props.buttons.map((button) => button)}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

const NavItem = (props) => {
  const className = props.isSelected ? 'e-list-text nav-text selected' : 'e-list-text nav-text';
  return (
    <li
      id={props.id}
      className="e-list-item e-level-1"
      role="treeitem"
      data-uid="3"
      aria-level="1"
      onClick={props.changeRoute}
    >
      <div className="e-fullrow"></div>
      <div className="e-text-content remove-padding">
        <span className={className}><props.icon sx={{ mr: '3px' }} />{props.title}</span>
      </div>
    </li>
  );
}

const SideBar = (props) => {
  const navigator = useNavigate();
  const location = useLocation();
  const themeLoaderRef = useRef(null);
  const [path, setPath] = useState("/");
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [userName, setUsername] = useState(null);
  const { isMobile } = useIsMobile();


  useEffect(() => {

    //set user name
    const user = localStorage.getItem('accountInfo');
    if (user) {
      const userInfo = JSON.parse(user);
      setUsername(userInfo.Name);
    }

    //Init Theme
    const settings = new AthenaSettings();
    const darkMode = localStorage.getItem('darkMode') ? localStorage.getItem('darkMode') : `${settings.value.darkMode}`;
    if (darkMode) {
      const isDarkMode = JSON.parse(darkMode);
      //default theme => light
      if (isDarkMode) {
        themeLoaderRef.current.show();
        setTimeout(() => {
          ThemeSwitcher.setTheme(isDarkMode, true).then(() => {
            themeLoaderRef.current.hide();
          });
        }, 200);
        document.getElementsByTagName('body')[0].style.backgroundColor = null;
      }
      else document.getElementsByTagName('body')[0].style.backgroundColor = '#f3f2f1';

      setIsDarkMode(isDarkMode);
    }
    else {
      document.getElementsByTagName('body')[0].style.backgroundColor = '#f3f2f1';
    }

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const newPath = location.pathname;
    if (path !== newPath) {
      //collapse menu on mobile when path changes
      if (isMobile) SideBar.collapse(isMobile);
      //add visible/expand class to prevent double clicking on the menu
      else if (path === '/') SideBar.expand(isMobile);
    }
    setPath(newPath);
    // eslint-disable-next-line
  }, [location]);

  const switchChangeHandler = (event) => {
    const darkMode = event.target.checked;
    themeLoaderRef.current.show();
    ThemeSwitcher.setTheme(event.target.checked).then(() => {
      themeLoaderRef.current.hide();
    });
    if (darkMode) document.getElementsByTagName('body')[0].style.backgroundColor = null;
    else document.getElementsByTagName('body')[0].style.backgroundColor = '#f3f2f1';

    setIsDarkMode(darkMode);
  }

  const changeRoute = (path) => {
    navigator(path);
  }

  const toggleMenu = (isLocationOnChange) => {
    if (isMobile) {
      if (!isLocationOnChange) {
        if (!SideBar.isExpanded(isMobile)) SideBar.expand(isMobile);
        else SideBar.collapse(isMobile);
      }
      else SideBar.collapse(isMobile);
    }
    else {
      if (props.isLogin) {
        if (!SideBar.isExpanded(isMobile)) SideBar.expand(isMobile);
        else SideBar.collapse(isMobile);
      }
    }
  }

  const logoutHandler = () => {
    if (isMobile) {
      SideBar.collapse(isMobile);
    }
    else {
      SideBar.expand(isMobile);
    }

    props.logoutOnClick();
  }

  return (
    <>
      <ThemeLoader ref={themeLoaderRef} darkMode={isDarkMode} />
      <div className="control-section" id="sidebar-wrapper2">
        <div>
          <div>
            <nav
              id="defaultToolbar"
              className="e-control e-toolbar e-lib e-keyboard"
            >
              <div className="e-toolbar-items e-tbar-pos">
                <div className="e-toolbar-left">
                  {props.isLogin && <IconButton
                    id='menuButton'
                    icon={Menu}
                    onClick={() => toggleMenu()}
                    tooltip='Toggle Menu'
                  />}
                  <div
                    className="e-toolbar-item e-template"
                    aria-disabled="false"
                  >
                    <a className="navbar-brand" href="/">
                      {props.isLogin ? 'Kim Hock Corp' : 'Find Plus'}
                    </a>
                  </div>
                </div>
                <div
                  className="e-toolbar-center"
                  style={{ marginleft: "821.5px" }}
                ></div>
                <div className="e-toolbar-right">
                  {props.isLogin && <div className="nav-link logout" onClick={logoutHandler} style={{ cursor: 'pointer' }}>
                    <LogoutIcon sx={{ mr: "5px" }} /> Logout
                  </div>}
                  <a className="navbar-brand right" href="/">
                    {props.isLogin ? 'Kim Hock Corp' : 'Find Plus'}
                  </a>
                </div>
              </div>
            </nav>
          </div>
        </div>
        <div className="maincontent">
          {props.children}
        </div>
        <SidebarComponent
          enableGestures={false}
          id="defaultSidebar"
          ref={() => { }}
          className="default-sidebar"
          target=".maincontent"
          position="Left"
          animate={false}
        >

          <div
            id="defaultTree"
            className="e-control e-treeview e-lib e-fullrow-wrap e-touch e-keyboard"
            role="tree"
            tabIndex="0"
            aria-activedescendant="defaultTree_active"
          >
            {props.isLogin && <ul className="e-list-parent e-ul " role="tree">
              {/* <NavItem title="DASHBOARD" isSelected={path === "/dashboard"} changeRoute={() => changeRoute('dashboard')} icon={HomeIcon} /> */}
              <NavItem title="SCHEDULER" isSelected={path === "/scheduler"} changeRoute={() => changeRoute('scheduler')} icon={CalendarTodayIcon} />
              <NavItem title="RECURRING JOBS" isSelected={path === "/recurringjobs"} changeRoute={() => changeRoute('recurringjobs')} icon={EventRepeatIcon} />
              <NavItem title="INTERNAL DESPATCH" isSelected={path === "/internaldespatch"} changeRoute={() => changeRoute('internaldespatch')} icon={TodayIcon} />
              <NavItem title="CUSTOMERS" isSelected={path === "/customer"} changeRoute={() => changeRoute('customer')} icon={PeopleAltIcon} />
              {/* <NavItem title="ZONES" isSelected={path === "/zone"} changeRoute={() => changeRoute('zone')} icon={LocationOnIcon} /> */}
              <NavItem title="DRIVERS" isSelected={path === "/driver"} changeRoute={() => changeRoute('driver')} icon={AlbumIcon} />
              {/* <NavItem title="VEHICLES" isSelected={path === "/vehicle"} changeRoute={() => changeRoute('vehicle')} icon={LocalShippingIcon} /> */}
              <NavItem title="SERVICE" isSelected={path === "/maintenance"} changeRoute={() => changeRoute('maintenance')} icon={Build} />
              <NavItem title="REPORTS" isSelected={path.includes('/report')} changeRoute={() => changeRoute('report/vmr')} icon={Summarize} />
              {/* <NavItem title="CRP" isSelected={path === "/crp"} changeRoute={() => changeRoute('crp')} icon={ListAlt} /> */}
              {/* <NavItem title="TRIP RATE" isSelected={path === "/triprate"} changeRoute={() => changeRoute('triprate')} icon={TripOrigin} /> */}
              <NavItem id="logoutNavItem" title="LOGOUT" isSelected={false} changeRoute={props.logoutOnClick} icon={LogoutIcon} />
            </ul>}
          </div>
        </SidebarComponent>
        <div>
          <footer
            id="defaultFooter"
            className="e-control e-toolbar e-lib defaultFooter e-keyboard"
          >
            <div className="e-toolbar-items e-tbar-pos">
              <div className="e-toolbar-left">
                <div
                  className="e-toolbar-item e-template"
                  aria-disabled="false"
                >
                  <label className="footer-title" style={{ marginRight: 15 }}>©2023 W-Locate Pte Ltd</label>
                  {props.isLogin && userName && <label className='footer-title' style={{ position: 'relative' }}>
                    <span className='e-icons e-user' style={{ position: 'absolute', top: 6, marginRight: 5 }}></span>
                    <span style={{ marginLeft: 23 }}>{userName}</span>
                  </label>}
                </div>
              </div>
              <div
                className="e-toolbar-center"
                style={{ marginleft: "821.5px" }}
              ></div>
              <div className="e-toolbar-right">
                <ThemeSwitch
                  isDarkMode={isDarkMode}
                  onChange={switchChangeHandler}
                />
              </div>
            </div>
          </footer>
        </div>
      </div>
    </>
  );
}

SideBar.getSidebarElem = () => document.getElementById('defaultSidebar');
SideBar.getMainContentElem = () => document.getElementsByClassName('maincontent')[0];
SideBar.isExpanded = function (isMobile) {
  const sidebar = SideBar.getSidebarElem();

  if (sidebar) {
    if (isMobile) return sidebar.classList.contains('visible');
    else return sidebar.classList.contains('expand');
  }
}
SideBar.expand = function (isMobile) {
  const sidebar = SideBar.getSidebarElem();
  const mainContent = SideBar.getMainContentElem();

  if (sidebar && mainContent) {
    if (isMobile) {
      sidebar.classList.add('visible');
    }
    else {
      sidebar.classList.remove('collapse');
      mainContent.classList.remove('collapse');
      sidebar.classList.add('expand');
      mainContent.classList.add('expand');
    }
  }
}

SideBar.collapse = function (isMobile) {
  const sidebar = SideBar.getSidebarElem();
  const mainContent = SideBar.getMainContentElem();

  if (sidebar && mainContent) {
    if (isMobile) {
      sidebar.classList.remove('visible');
    }
    else {
      sidebar.classList.remove('expand');
      mainContent.classList.remove('expand');
      sidebar.classList.add('collapse');
      mainContent.classList.add('collapse');
    }
  }
}

SideBar.embed = function () {
  const body = document.body;
  if (body?.style && body.style.overflowY !== 'auto') body.style.overflowY = 'auto';
}

export default SideBar;