import React from "react";
import { useParams } from "react-router-dom";
import SideBar from "../../../Components/SideBar";
import Spacing from "../../../Components/Spacing";
import HttpAxios from "../../../Utils/AxiosHttp";
import { SwalRequest } from "../../../Utils/Swal";
import OrderExcel from "./Components/OrderExcel";
import OrderFilter from "./Components/OrderFilter";
import OrderTable from "./Components/OrderTable";
import OrderModel from "./Models/OrderModel";
import moment from "moment";
import {SentryBreadcrumb} from "../../../Utils/SentryUtils";

export default function DriverOrder(props) {
    const { token } = useParams();
    const httpAxios = React.useRef(new HttpAxios({
        token: token
    }));
    const orderFilterRef = React.useRef();
    const orderExcelRef = React.useRef();
    const orderTableRef = React.useRef();
    const vehicleList = React.useRef([]);
    const orderModel = React.useRef(new OrderModel());

    React.useEffect(() => {
        initService();
        if (props?.isEmbed) {
            SideBar.embed();
        }
        // eslint-disable-next-line
    }, []);


    const initService = async () => {
        SwalRequest({
            title: 'Fetching  Driver Order Data',
            text: 'Please wait...',
            request: async () => {
                try {
                    const axios = httpAxios.current;

                    SentryBreadcrumb.operation({
                        message: 'Fetching Filter Data'
                    });
                    const [vehicles, drivers, customers, sites, returnSites] = await Promise.all([
                        axios.post(`Vehicle?Token=${axios.token}`),
                        axios.post(`Driver?Token=${axios.token}`),
                        axios.post(`Customer?Token=${axios.token}`),
                        axios.post(`Zone?Token=${axios.token}`),
                        axios.post(`Zone/origin?Token=${axios.token}`)
                    ]);

                    //Hide test vehicles & show only vehicles with the category of Crane or Truck
                    const filteredVehicles = vehicles.filter(f => !f.Name.includes('WL') && ["Crane", "Truck", "Prime Mover"].includes(f.Category) && f?.UnderMaintenance !== 1);
                    orderFilterRef.current.setDataSource({
                        vehicles: filteredVehicles,
                        customers: customers,
                        sites: sites,
                        returnSites: returnSites,
                        drivers: drivers
                    });
                    //used for getting vehicle info of future orders
                    vehicleList.current = filteredVehicles;

                    const dateStart = moment('00:00:00', 'HH:mm:ss');
                    const dateEnd = dateStart.clone().add(1, 'day');
                    await filterRequest({
                        DateStart: dateStart.set('hours', 8).set('minutes', 30).utc().toISOString(),
                        DateEnd: dateEnd.utc().toISOString()
                    });
                } catch (error) {
                    SentryBreadcrumb.error({
                        message: 'Fetching Filter Data',
                        error: error
                    });
                    throw error;
                }
            },
            failedTitle: 'Failed to fetch Driver Order data'
        });
    }

    const filterHandler = (filter) => {
        SwalRequest({
            title: 'Fetching Driver Order Data',
            text: 'Please wait...',
            request: async () => await filterRequest(filter),
            failedTitle: 'Failed to fetch Driver Order data'
        });
    }

    const filterRequest = async (filter) => {
        try {
            if (!filter.DateStart) {
                const dateStart = moment('00:00:00', 'HH:mm:ss');
                filter.DateStart = dateStart.clone().set('hours', 8).set('minutes', 30).utc().toISOString();
                filter.DateEnd = dateStart.clone().add(1, 'day').utc().toISOString()
            }
            else {
                filter.DateStart = moment(filter.DateStart).set('hours', 8).set('minutes', 30).utc().toISOString();
            }

            const driverOrderParams = {
                filter: filter,
                canFetchJob: true,
                canFetchOrder: true,
                pageNum: 1
            }
            
            const dataSource = {
                jobs: [],
                orders: []
            };

            do {
                SentryBreadcrumb.operation({
                    message: 'Get driver orders'
                });
                const response = await getDriverOrderService(driverOrderParams);
                if (driverOrderParams.canFetchJob) {
                    driverOrderParams.canFetchJob = response.jobs.length > 0;
                    dataSource.jobs = dataSource.jobs.concat(response.jobs);
                    if (driverOrderParams.canFetchJob) driverOrderParams.jobItemID = response.jobs.at(-1).JobID;
                }
                if (driverOrderParams.canFetchOrder) {
                    driverOrderParams.canFetchOrder = response.orders.length > 0;
                    dataSource.orders = dataSource.orders.concat(response.orders);
                    if (driverOrderParams.canFetchOrder) driverOrderParams.orderItemID = response.orders.at(-1).OrderID;
                }
                driverOrderParams.pageNum++;
            } while (driverOrderParams.canFetchJob || driverOrderParams.canFetchOrder);

            const vehicles = filter.VehicleID ? vehicleList.current.filter(f => f.VehicleID === filter.VehicleID) : vehicleList.current;

            //Sort orders by Schedule Type
            const schedulerTypeOrder = {
                "SINGLE": 1,
                "MULTIPLE": 2
            }
            dataSource.orders.sort((a, b) => schedulerTypeOrder[a.ScheduleType] - schedulerTypeOrder[b.ScheduleType]);

            //Hide those recurring orders if the single order is already created
            dataSource.orders = dataSource.orders.reduce((prev, curr) => {
                if (curr.ScheduleType === 'SINGLE') {
                    //Retrieve Recurring Order ID in ContractCode (Option 1)
                    if (curr.ContractCode) curr.RecurringOrderID = curr.ContractCode.split(',')[0];
                    else {
                        //Retrieve Recurring Order ID in Remarks (Option 2)
                        let match = curr?.Remarks?.match(/From recurring job: (\d+)/);
                        if (match) {
                            curr.RecurringOrderCode = match[1];
                        }
                    }
                    return prev.concat([curr]);
                }
                else {
                    //Checks if single-day order is generated
                    const hasSingleOrder = !!prev.find(f => (f?.RecurringOrderID !== undefined && parseInt(f.RecurringOrderID) === curr.OrderID)
                        || (f?.RecurringOrderCode !== undefined && f.RecurringOrderCode === curr.OrderCode));

                    // let recurToday = false;
                    // //Removed recurring orders base on its RecurringDays
                    // if (curr.WorkTime === 'OVERTIME') {
                    //     //Monday - Saturday
                    //     const overTimeDay = [1, 2, 3, 4, 5, 6];
                    //     //Checks if target day is matched with the overtime day
                    //     if (overTimeDay.includes(day)) {
                    //         recurToday = curr.RecurringDays.includes(`${day}`);
                    //     }
                    // }
                    // else if (curr.WorkTime === 'HOLIDAY') {
                    //     //0 => Sunday
                    //     recurToday = day === 0;
                    // }
                    // else recurToday = curr.RecurringDays === "" || curr.RecurringDays.includes(`${day}`);


                    //Committed (1) and In-progress (2)
                    const allowedOrderStatus = [1, 2];
                    //Checks if recurring jobs is committed or in-progress
                    const showRecurringJobs = allowedOrderStatus.includes(curr?.StatusID);

                    const currTime = moment();
                    const dailyTimeLimit = moment().set('hour', 18).set('minute', 0).set('second', 0).set('millisecond', 0);

                    const timeChecker = (moment(currTime).isBefore(dailyTimeLimit) && moment(filter.DateStart).isSameOrAfter(currTime, 'day'))
                        || moment(filter.DateStart).isBefore(currTime, 'day')

                    //Ignore Recurring Jobs if the single-day order is already generated or the recurring jobs recur today
                    // if (!hasSingleOrder && recurToday && showRecurringJobs) return prev.concat([curr]);
                    if (!hasSingleOrder && showRecurringJobs && timeChecker) return prev.concat([curr]);
                    return prev;
                }
            }, []);

            //Removed pending(0) orders
            dataSource.orders = dataSource.orders.filter(f => [1, 2, 3, 4].includes((f?.StatusID ?? 0)) && f?.UnderMaintenance !== 1);

            //Removed Cancelled jobs (Removed here because cancelled jobs is used for removing recurring orders
            dataSource.orders = dataSource.orders.filter(f => f.StatusID !== 4);

            orderModel.current = new OrderModel(dataSource.jobs, dataSource.orders, filter.DateStart, vehicles);
            orderTableRef.current.setDataSource(orderModel.current.getTableData());
        } catch (error) {
            console.log(error);
            SentryBreadcrumb.error({
                message: 'Get driver orders'
            });
            throw error;
        }
    };

    const getDriverOrderService = async (params) => await new Promise((resolve, reject) => {
        const axios = httpAxios.current;

        let jobRequest, orderRequest;
        if (params.canFetchJob) {
            const jobPayload = {
                ...params.filter,
                PageNumber: params.pageNum,
                PageSize: 100,
            }
            if (params.jobItemID) jobPayload.ItemID = params.jobItemID;
            SentryBreadcrumb.requestPayload({
                message: 'POST /Job',
                data: jobPayload
            });
            jobRequest = axios.post(`Job?Token=${axios.token}`, jobPayload);
        }
        if (params.canFetchOrder) {
            const orderPayload = {
                ...params.filter,
                EffectiveOnly: true,
                PageNumber: params.pageNum,
                PageSize: 100
            }
            if (params.orderItemID) orderPayload.ItemID = params.orderItemID;
            if (orderPayload?.ShipToCode) delete orderPayload.ShipToCode;
            if (orderPayload?.ShipToName) delete orderPayload.ShipToName;
            if (orderPayload?.PlantCode) delete orderPayload.PlantCode;
            if (orderPayload?.PlantName) delete orderPayload.PlantName;
            if (orderPayload?.OrderCode) delete orderPayload.OrderCode;
            SentryBreadcrumb.requestPayload({
                message: 'POST /Order',
                data: orderPayload
            });
            orderRequest = axios.post(`Order?Token=${axios.token}`, orderPayload);
        }

        setTimeout(() => {
            Promise.all([jobRequest, orderRequest]).then(([jobResponse, orderResponse]) => {
                resolve({
                    jobs: jobResponse,
                    orders: orderResponse
                });
            }, (e) => {
                SentryBreadcrumb.error({
                    message: 'Failed to fetch Order or Job request',
                    error: e
                });
                reject(e);
            });
        }, 500);
    });

    const excelExportHandler = () => {
        orderExcelRef.current.setExcelData(orderModel.current.getExportData());
    }

    return (
        <>
            <OrderFilter ref={orderFilterRef} onFilter={filterHandler} />
            <Spacing />
            <OrderTable ref={orderTableRef} onExcelExport={excelExportHandler} />
            <OrderExcel ref={orderExcelRef} />
        </>
    );
}