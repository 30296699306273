import moment from 'moment-timezone';
import 'moment/locale/en-sg';

export default function OrderModel(jobs, orders, dateStart, khVehicles) {
    this.data = orders;
    this.tableData = {
        main: [],
        sub: []
    };
    this.exportData = {
        jobCount: [],
        data: []
    };

    this.getExportData = function () {
        return {
            date: dateStart,
            maxJobCount: Math.max(...this.exportData.jobCount.map(item => item.count)),
            data: this.exportData.data.map(item => {
                delete item.VehicleID;
                return item;
            })
        };
    }

    this.getTableData = function () {
        return this.tableData;
    }

    this.mapMainItem = function (value) {
        return {
            ID: value.ID,
            Date: value.Date,
            VehicleID: value.VehicleID,
            Vehicle: value.Vehicle,
            Driver: value.Driver,
            JobCount: 0,
            SiteCount: 0,
            TicketCount: 0
        };
    }

    this.mapSubItem = function (value) {
        return {
            ID: value.ID,
            // jobSiteID: this.generateJobSiteID(value),
            Date: moment(value.DeliveryTimestamp).toDate(),
            VehicleID: value.VehicleID,
            Vehicle: value.Vehicle,
            OrderCode: value.OrderCode,
            Driver: value.Driver,
            HighlightVehicle: value.HighlightVehicle,
            Job: value.CustomerName,
            Remarks: value.Remarks,
            Type: value.Type,
            Site: value.ProjectLocation,
            Tickets: value.Jobs.filter(f => f.StatusID >= 5).length
        };
    }

    this.mapExportItem = function (value) {
        return {
            ID: value.ID,
            Date: moment(value.Date).format('YYYY-MMM-DD'),
            Category: value.Category,
            VehicleID: value.VehicleID,
            Vehicle: value.Vehicle,
            Driver: value.Driver,
            Jobs: []
        };
    }

    this.jobExportItem = function (newItem) {
        return {
            ID: this.generateExportJobID(newItem),
            HighlightVehicle: newItem.HighlightVehicle,
            OrderCode: newItem.OrderCode,
            Job: newItem.CustomerName,
            Site: newItem.ProjectLocation,
            Remarks: newItem.Remarks,
            WorkTime: newItem.WorkTime,
            Type: newItem.Type,
            Tickets: newItem.Tickets
        };
    }

    this.mapExportCount = function (value, count) {
        return {
            VehicleID: value.VehicleID,
            count: count
        };
    }

    this.removeCustomCode = (customer) => {
        if (customer) {
            let start = 0;
            const strArray = [...customer];
            for (let i = 0; i < strArray.length; i++) {
                start = i;
                if (strArray[i] === " ") break;
            }

            return customer.substring(++start, strArray.length);
        }

        return '';
    }

    // this.generateJobSiteID = (order) => {
    //     return `${order.VehicleID}${order.CustomerCode}${order.ProjectCode}${order.Type}`;
    // }

    this.generateExportJobID = (order) => {
        return `${order.VehicleID}${order.CustomerCode}${order.ProjectCode}`;
    }

    this.generateUniqueID = (job) => {
        // const timestamp = moment(job.LoadTimestamp).format('MMDDyyyy');
        // return timestamp + job.VehicleID.toString();
        return job.VehicleID.toString();
    }

    this.generateOrderUniqueID = (order) => {
        return `${order.CustomerAcctNo}${order.ProjectCode}${order.OrderCode}`;
    }

    this.mapOrderItem = (order, job) => {
        const highlightVehicle = order?.MaterialCode === 'Breakdown and Other' || order?.MaterialCode === 'Breakdown and Oth' || 
            order?.MaterialCode === "Absent" || order?.MaterialCode === "Annual Leave" || order?.MaterialCode === 'Medical Leave' ||
            order?.WorkTime === 'NIGHT_SHIFT';
        const JobLoadTimestamp = job?.LoadTimestamp ? job.LoadTimestamp : null;

        //Retrieve recurring job string
        const recurringString = order.Remarks?.match(/From recurring job: (\d+)/);

        //Remove Bin in the remarks
        order.Remarks = order?.Remarks ? (order.Remarks.includes('Bin:') ? order.Remarks.substring(0, order.Remarks.indexOf('Bin:')).trim() : order.Remarks) : " "

        //Remove from recurring job in remarks
        order.Remarks = order?.Remarks ? (order.Remarks.includes('From recurring job:') ? order.Remarks.substring(0, order.Remarks.indexOf('From recurring job:')).trim() : order.Remarks) : " "

        //Check if recurring string does not exist in remarks
        if (!order.Remarks.includes('From recurring job') && recurringString) {
            order.Remarks = `${order.Remarks?.trim()}\n${recurringString[0]?.trim()}`;
        }

        return {
            CustomOrderID: this.generateOrderUniqueID(order),
            OrderCode: order.OrderCode,
            OrderID: order.OrderID,
            HighlightVehicle: highlightVehicle,
            DeliveryTimestamp: order.DeliveryTimestamp,
            CustomerName: order.CustomerName,
            CustomerCode: order.CustomerAcctNo,
            Type: order.MaterialCode,
            ProjectLocation: this.removeCustomCode(order.ProjectLocation),
            ProjectCode: order.ProjectCode,
            PriorityID: order.PriorityID,
            Timestamp: order.Timestamp,
            LoadTimestamp: JobLoadTimestamp,
            WorkTime: order.WorkTime,
            Remarks: highlightVehicle && order?.Remarks ? order.Remarks : " ",
            Jobs: job ? [{
                OrderCode: job.OrderCode,
                TicketNum: job.TicketNum,
                LoadTimestamp: job.LoadTimestamp,
                StatusID: job?.StatusID ?? 0
            }] : []
        }
    }

    this.init = function () {
        let vehicles = [];
        const isFutureJobs = moment().isBefore(moment(dateStart));

        //List of orders with Tickets(Jobs)
        if (jobs?.length > 0 || orders?.length > 0) {
            if (!isFutureJobs) {
                const currentDate = moment().tz('Asia/Singapore');

                //6PM SG Time
                if (currentDate.get('h') >= 18) {
                    //Remove recurring jobs because WL generates single-day orders at 6 PM
                    orders = orders.filter(f => f.ScheduleType === 'SINGLE');
                }
                else {
                    //Remove recurring jobs that are not scheduled for the night shift
                    orders = orders.filter(f => f.ScheduleType === 'SINGLE' || (f.ScheduleType === 'MULTIPLE' && f?.WorkTime === 'NIGHT_SHIFT'));
                }

                jobs.sort((a, b) => a.Vehicle < b.Vehicle ? -1 : 1);
                jobs.forEach((job) => {
                    const isCurrentDate = moment(job.LoadTimestamp).format("L") === moment(dateStart).format("L");
                    const jobStatusId = job?.StatusID ?? 0;
                    if (jobStatusId >= 0 && isCurrentDate) {
                        const vehicleList = vehicles.filter(f => f.ID === this.generateUniqueID(job));

                        // const isSameDate = vehicleList.length > 0 && moment(job.LoadTimestamp).format("L") === moment(vehicleList[0].Date).format("L");
                        if (vehicleList.length > 0) {
                            const vehicle = vehicleList[0];
                            const index = vehicles.indexOf(vehicle);

                            const jobOrders = vehicle.Orders.filter(f => f.OrderCode === job.OrderCode);
                            if (jobOrders.length > 0) {
                                //Only count the trip if the ticket is completed (StatusID >=5)
                                if (jobStatusId >= 5) {
                                    const jobOrder = jobOrders[0];
                                    const index = vehicle.Orders.indexOf(jobOrder);

                                    if (jobOrder.LoadTimestamp === null && job?.LoadTimestamp) jobOrder.LoadTimestamp = job.LoadTimestamp;
                                    else if (jobOrder.LoadTimestamp !== null && job?.LoadTimestamp) {
                                        jobOrder.LoadTimestamp = moment(jobOrder.LoadTimestamp).isAfter(job.LoadTimestamp) ? job.LoadTimestamp : jobOrder.LoadTimestamp;
                                    }

                                    if (!vehicle.Driver) vehicle.Driver = job.Driver;

                                    jobOrder.Jobs.push({
                                        OrderCode: job.OrderCode,
                                        TicketNum: job.TicketNum,
                                        LoadTimestamp: job.LoadTimestamp,
                                        StatusID: job?.StatusID ?? 0,
                                    });

                                    vehicle.Orders[index] = jobOrder;
                                }
                            } else {
                                if (!vehicle.Driver) vehicle.Driver = job.Driver;
                                vehicle.Orders.push(...orders.filter(f => f.OrderCode === job.OrderCode).map(value => jobStatusId >= 5 ? this.mapOrderItem(value, job) : this.mapOrderItem(value)));
                            }

                            vehicles[index] = vehicle;
                        } else {
                            const vehicle = khVehicles.find(f => f.VehicleID === job.VehicleID)
                            vehicles.push({
                                ID: this.generateUniqueID(job),
                                Date: dateStart,
                                Category: vehicle?.Category ?? null,
                                VehicleID: job.VehicleID,
                                Vehicle: job.Vehicle,
                                Driver: job.Driver,
                                Orders: [
                                    //Only count the trip if the ticket is completed (StatusID >=5)
                                    ...orders.filter(f => f.OrderCode === job.OrderCode).map(value => jobStatusId >= 5 ? this.mapOrderItem(value, job) : this.mapOrderItem(value))
                                ],
                            });
                        }
                    }
                });


                //Commented: To hide NOT_AVAILABLE = -1, CANCELLED_TICKET = -2, EXPIRED_TICKET = -3 Jobs
                //Show orders where a single order has not yet started, and hide orders where the tickets are NOT_AVAILABLE (-1), CANCELLED_TICKET (-2), or EXPIRED_TICKET (-3)
                (orders?.filter(f => (f.ScheduleType === 'SINGLE' && (f?.StatusID ?? 0) > 0) || f.ScheduleType === 'MULTIPLE') ?? []).forEach(order => {
                    (order?.AssignedVehicles ?? []).forEach(assignedVehicle => {
                        const vehicle = vehicles.find(f => f.Vehicle === assignedVehicle.Value && !f.Orders.find(o => o.OrderCode === order.OrderCode));
                        if (vehicle) {
                            const index = vehicles.indexOf(vehicle);
                            vehicle.Orders.push(this.mapOrderItem(order));
                            vehicles[index] = vehicle;
                        } else {
                            const vehicleInfo = vehicles.find(f => f.Vehicle === assignedVehicle.Value);
                            const khVehicleInfo = khVehicles.find(f => f.Name === assignedVehicle.Value);
                            if (!vehicleInfo && khVehicleInfo) {
                                vehicles.push({
                                    ID: this.generateUniqueID({
                                        LoadTimestamp: dateStart,
                                        VehicleID: khVehicleInfo.VehicleID
                                    }),
                                    Date: dateStart,
                                    Category: khVehicleInfo.Category,
                                    VehicleID: khVehicleInfo.VehicleID,
                                    Vehicle: khVehicleInfo.Name,
                                    Driver: khVehicleInfo.Driver,
                                    Jobs: [],
                                    Orders: [this.mapOrderItem(order)],
                                });
                            }
                        }
                    });
                });
            } else {
                //List of orders without Tickets(Jobs)
                if (orders?.length > 0) {
                    khVehicles = khVehicles ?? [];
                    orders.forEach((order) => {
                        (order?.AssignedVehicles ?? []).forEach((assignedVehicle) => {
                            const vehicleInfo = khVehicles.filter(f => f.Name === assignedVehicle.Value)[0];
                            if (vehicleInfo) {
                                const isExist = vehicles.filter(f => f.Vehicle === vehicleInfo.Name).length > 0;

                                if (!isExist) {
                                    vehicles.push({
                                        ID: this.generateUniqueID({
                                            LoadTimestamp: dateStart,
                                            VehicleID: vehicleInfo.VehicleID
                                        }),
                                        Date: dateStart,
                                        Category: vehicleInfo.Category,
                                        VehicleID: vehicleInfo.VehicleID,
                                        Vehicle: vehicleInfo.Name,
                                        Driver: vehicleInfo.Driver,
                                        Jobs: [],
                                        Orders: [this.mapOrderItem(order)],
                                    });
                                } else {
                                    const vehicle = vehicles.filter(f => f.VehicleID === vehicleInfo.VehicleID)[0];
                                    const index = vehicles.indexOf(vehicle);
                                    vehicle.Orders.push(this.mapOrderItem(order));
                                    vehicles[index] = vehicle;
                                }
                            }
                        });
                    });
                }
            }
        }

        if (khVehicles?.length > 0) {
            //List of vehicles without orders/jobs
            const unAssignedVehicles = khVehicles.filter(f => vehicles.filter(v => v.VehicleID === f.VehicleID).length === 0);
            unAssignedVehicles.forEach(vehicle => {
                vehicles.push({
                    ID: this.generateUniqueID({
                        LoadTimestamp: dateStart,
                        VehicleID: vehicle.VehicleID
                    }),
                    Date: dateStart,
                    Category: vehicle.Category,
                    VehicleID: vehicle.VehicleID,
                    Vehicle: vehicle.Name,
                    Driver: vehicle.Driver,
                    Jobs: [],
                    Orders: [],
                });
            });
        }

        //Sort by Vehicle Name
        vehicles.sort((a, b) => a.Vehicle > b.Vehicle ? 1 : -1);

        //Sort by category
        const categoryOrder = {
            "Truck": 1,
            "Crane": 2,
            "Prime Mover": 3
        };
        vehicles.sort((a, b) => categoryOrder[a.Category] - categoryOrder[b.Category]);

        (vehicles ?? []).forEach((value) => {
            const main = this.mapMainItem(value);
            const jobCountItem = this.mapExportCount(value, 0);
            let exportItem = this.mapExportItem(value);

            if (value.Orders.length > 0) {
                value.Orders.sort((a, b) => {
                    // const isTrue = moment(a.DeliveryTimestamp).isBefore(b.DeliveryTimestamp) && a.PriorityID > b.PriorityID && moment(a.Timestamp).isBefore(b.Timestamp);
                    const orderA = this.mapSubItem(a);
                    const orderB = this.mapSubItem(b);

                    let isTrue = false;
                    if (!isTrue) isTrue = orderA.Tickets > orderB.Tickets;
                    if (!isTrue && orderA.Tickets === 0 && orderB.Tickets === 0) {
                        const isSame = moment(a.DeliveryTimestamp).isSame(b.DeliveryTimestamp);
                        if (isSame) isTrue = a.OrderID < b.OrderID
                        else isTrue = moment(a.DeliveryTimestamp).isBefore(b.DeliveryTimestamp)
                    }
                    if (!isTrue && a.LoadTimestamp && b.LoadTimestamp) isTrue = moment(a.LoadTimestamp).isBefore(b.LoadTimestamp);
                    
                    return isTrue ? -1 : 1
                });

                const finalOrders = [];
                value.Orders.forEach((order) => {
                    const orderList = finalOrders.filter(f => f.CustomOrderID === order.CustomOrderID);
                    if (orderList.length > 0) {
                        const currOrder = orderList[0];
                        const index = finalOrders.indexOf(currOrder);
                        currOrder.Jobs = currOrder.Jobs.concat(order.Jobs);
                        finalOrders[index] = currOrder;
                    }
                    else {
                        finalOrders.push(order);
                    }
                });
                value.Orders = finalOrders;

                value.Orders.forEach((order) => {
                    order.ID = value.ID;
                    order.Vehicle = value.Vehicle;
                    order.Driver = value.Driver;
                    order.Category = value.Category;
                    order.VehicleID = value.VehicleID;

                    // const existingSubItem = this.tableData.sub.find(f => f.jobSiteID === this.generateJobSiteID(order));
                    const subItem = this.mapSubItem(order);

                    order.Tickets = subItem.Tickets;
                    const jobExportItem = this.jobExportItem(order);
                    if (jobExportItem.Tickets >= 100) {
                        jobExportItem.Tickets = 99;
                    }
                    if (isFutureJobs) jobExportItem.Tickets = ' ';

                    exportItem.Jobs.push(jobExportItem);

                    // if (!existingSubItem) {
                    //     this.tableData.sub.push(subItem);
                    //     main.SiteCount++;
                    //     main.JobCount++;
                    // }
                    // else {
                    //     const index = this.tableData.sub.indexOf(existingSubItem);
                    //     existingSubItem.Tickets += subItem.Tickets;
                    //     this.tableData.sub[index] = existingSubItem;
                    // }

                    this.tableData.sub.push(subItem);

                    jobCountItem.count++;
                    main.SiteCount++;
                    main.JobCount++;
                    main.TicketCount += subItem.Tickets;
                });
            }


            this.exportData.data.push(exportItem);
            this.exportData.jobCount.push(jobCountItem);
            this.tableData.main.push(main);
        });
    }

    this.init();
}