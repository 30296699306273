import React from "react";
import { SpreadsheetComponent, SheetsDirective, SheetDirective, CellDirective, CellsDirective, ColumnsDirective, ColumnDirective, RowsDirective, RowDirective } from "@syncfusion/ej2-react-spreadsheet";
import { SwalRequest } from "../../../../Utils/Swal";
import { HttpAxiosServers } from "../../../../Utils/AxiosHttp";
import moment from "moment";
import {SentryBreadcrumb} from "../../../../Utils/SentryUtils";

const OrderExcel = React.forwardRef((props, ref) => {
    // const letters = ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z"];
    const spreadsheetRef = React.useRef();
    const [excelData, setExcelData] = React.useState({
        maxJobCount: 0,
        date: new Date(),
        data: []
    });

    React.useImperativeHandle(ref, () => {
        return {
            setExcelData(data) {
                setExcelData({
                    ...excelData,
                    ...data
                });
            }
        }
    });

    const createdHandler = () => {
        SwalRequest({
            title: 'Exporting Excel File',
            text: 'Please wait...',
            request: async () => new Promise((resolve, reject) => {
                SentryBreadcrumb.operation({
                    message: 'Export Excel File'
                });
                spreadsheetRef.current.autoFit('A:B');

                setTimeout(() => {
                    spreadsheetRef.current.saveAsJson().then(response => {
                        const fileName = `${moment(excelData.date).format("YYYY-MMM-DD")} Driver Order`;
                        const extension = 'Xlsx';
                        var formData = new FormData();
                        formData.append('JSONData', JSON.stringify(response.jsonObject.Workbook));
                        formData.append('FileName', fileName);
                        formData.append('SaveType', extension);
                        formData.append('FileContentType', extension);
                        formData.append('VersionType', extension);
                        formData.append('PDFLayoutSettings', JSON.stringify({ "fitSheetOnOnePage": false }));

                        fetch(`${HttpAxiosServers.kimhock}/Report/ExtractReport`, {
                            method: 'POST',
                            body: formData
                        }).then(response => {
                            response.blob().then(data => {
                                const url = URL.createObjectURL(new Blob([data], { type: 'application/octet-stream' }));
                                const anchor = document.createElement('a');
                                anchor.href = url;
                                anchor.setAttribute('download', `${fileName}.${extension}`);
                                document.body.appendChild(anchor);
                                anchor.click();
                                anchor.remove();
                                resolve();
                            }, () => reject('Failed to generate file.'));
                        }, () => reject('Failed to Extract Report.'));
                    }, () => reject('Failed to saveAsJSON.'));
                }, 1000);
            }),
            successTitle: 'Export is successful!',
            failedTitle: 'Unable to export excel file',
            failedText: (error) => error,
            onSuccess: onCompleted,
            onFailed: onCompleted
        });
    }

    const onCompleted = () => {
        setExcelData({
            maxJobCount: 0,
            data: []
        });
    }

    const driverFormat = (value) => {
        let leadZero = '';
        for (let i = 0; i < value.length; i++) {
            leadZero += '0'
        }

        return leadZero;
    }

    function jobIdFormatter(value) {
        let leadZero = "";
        for (var i = 0; i < value.length; i++) {
            leadZero += "0";
        }
        return leadZero;
    }

    const driverAlignment = (value) => {
        if (value?.length > 0) {
            if (value.substring(0, 1) === '0') return 'center'
        }

        return 'left';
    }

    return (
        excelData.maxJobCount !== 0 && <div style={{ display: 'none' }}>
            <SpreadsheetComponent
                ref={spreadsheetRef}
                created={createdHandler}>
                <SheetsDirective>
                    <SheetDirective name={`${moment(excelData.date).format("YYYY-MMM-DD")}`}>
                        <RowsDirective>
                            <RowDirective>
                                <CellsDirective>
                                    {
                                        (() => {
                                            const columns = [];
                                            columns.push(
                                                <CellDirective key="date" style={{ fontWeight: 'bold', backgroundColor: '#AEAAAA', border: '1px solid #000000', verticalAlign: 'top' }} value="DATE"></CellDirective>
                                            );
                                            columns.push(
                                                <CellDirective key="Vehicle" style={{ fontWeight: 'bold', backgroundColor: '#AEAAAA', border: '1px solid #000000', verticalAlign: 'top' }} value="VEHICLE"></CellDirective>
                                            );
                                            columns.push(
                                                <CellDirective key="Category" style={{ fontWeight: 'bold', backgroundColor: '#AEAAAA', border: '1px solid #000000', verticalAlign: 'top' }} value="CATEGORY"></CellDirective>
                                            );
                                            columns.push(
                                                <CellDirective key="Driver" style={{ fontWeight: 'bold', backgroundColor: '#AEAAAA', border: '1px solid #000000', verticalAlign: 'top' }} value="DRIVER"></CellDirective>
                                            );

                                            for (let i = 1; i <= excelData.maxJobCount; i++) {
                                                columns.push(
                                                    <CellDirective key={`OrderCode${i}`} style={{ fontWeight: 'bold', backgroundColor: '#FFFF00', border: '1px solid #000000', verticalAlign: 'top' }} value={`JOB${i}`}></CellDirective>
                                                );
                                                columns.push(
                                                    <CellDirective key={`Job${i}`} style={{ fontWeight: 'bold', backgroundColor: '#AEAAAA', border: '1px solid #000000', verticalAlign: 'top' }} value={`CUSTOMER${i}`}></CellDirective>
                                                );
                                                columns.push(
                                                    <CellDirective key={`Site${i}`} style={{ fontWeight: 'bold', backgroundColor: '#AEAAAA', border: '1px solid #000000', verticalAlign: 'top' }} value={`SITE${i}`}></CellDirective>
                                                );
                                                columns.push(
                                                    <CellDirective key={`Type${i}`} style={{ fontWeight: 'bold', backgroundColor: '#AEAAAA', border: '1px solid #000000', verticalAlign: 'top' }} value={`TYPE${i}`}></CellDirective>
                                                );
                                                columns.push(
                                                    <CellDirective key={`Remark${i}`} style={{ fontWeight: 'bold', backgroundColor: '#AEAAAA', border: '1px solid #000000', verticalAlign: 'top' }} value={`REMARK${i}`}></CellDirective>
                                                );
                                                columns.push(
                                                    <CellDirective key={`Tickets${i}`} style={{ fontWeight: 'bold', backgroundColor: '#AEAAAA', border: '1px solid #000000', verticalAlign: 'top' }} value={`TICKETS${i}`}></CellDirective>
                                                );
                                            }
                                            return columns;
                                        })()
                                    }
                                </CellsDirective>
                            </RowDirective>
                            {
                                excelData.data.map((value, i) => {
                                    const items = [];
                                    // let alternateBG = i % 2 === 0 ? '#d3d3d3' : null;
                                    items.push(
                                        <CellDirective key={`Date${i}`} style={{ border: '1px solid #000000', verticalAlign: 'top' }} format="@" value={value.Date}></CellDirective>
                                    );
                                    items.push(
                                        <CellDirective key={`Vehicle${i}`} format={driverFormat(value.Vehicle)} style={{ border: '1px solid #000000', verticalAlign: 'top', textAlign: driverAlignment(value.Vehicle) }} value={value.Vehicle}></CellDirective>
                                    );
                                    items.push(
                                        <CellDirective key={`Category${i}`} format={driverFormat(value.Vehicle)} style={{ border: '1px solid #000000', verticalAlign: 'top', textAlign: driverAlignment(value.Vehicle) }} value={value.Category}></CellDirective>
                                    );
                                    items.push(
                                        <CellDirective key={`Driver${i}`} wrap={true} format={driverFormat(value.Driver)} style={{ border: '1px solid #000000', backgroundColor: !value?.Driver ? '#FFFF00' : undefined, verticalAlign: 'top', textAlign: driverAlignment(value.Driver) }} value={value.Driver}></CellDirective>
                                    );

                                    value.Jobs.forEach((value, index) => {
                                        const alternateBG = value.HighlightVehicle ? (value.WorkTime === 'NIGHT_SHIFT' ? '#F7C7AC' : '#BAD7ED') : (index % 2 === 0 ? '#E7E6E6' : null);

                                        items.push(
                                            <CellDirective key={`OrderCode${index}`} style={{ backgroundColor: alternateBG, border: '1px solid #000000', verticalAlign: 'top', textAlign: 'left' }}  format={jobIdFormatter(value.OrderCode)} value={value.OrderCode}></CellDirective>
                                        );
                                        items.push(
                                            <CellDirective key={`Job${index}`} wrap={true} style={{ backgroundColor: alternateBG, border: '1px solid #000000', verticalAlign: 'top' }} value={value.Job}></CellDirective>
                                        );
                                        items.push(
                                            <CellDirective key={`Site${index}`} wrap={true} style={{ backgroundColor: alternateBG, border: '1px solid #000000', verticalAlign: 'top' }} value={value.Site}></CellDirective>
                                        );
                                        items.push(
                                            <CellDirective key={`Type${index}`} wrap={true} style={{ backgroundColor: alternateBG, border: '1px solid #000000', verticalAlign: 'top' }} value={value.Type}></CellDirective>
                                        );
                                        items.push(
                                            <CellDirective key={`Remark${index}`} wrap={true} style={{ backgroundColor: alternateBG, border: '1px solid #000000', verticalAlign: 'top' }} value={value.Remarks ? value.Remarks : " "}></CellDirective>
                                        );
                                        items.push(
                                            <CellDirective key={`Tickets${index}`} style={{ backgroundColor: alternateBG, border: '1px solid #000000', verticalAlign: 'top', textAlign: 'center' }} value={value.Tickets}></CellDirective>
                                        );
                                    });

                                    for (let j = 1; j <= (excelData.maxJobCount - value.Jobs.length); j++) {
                                        const alternateBG = (j + value.Jobs.length) % 2 === 1 ? '#E7E6E6' : null;
                                        items.push(
                                            <CellDirective key={`border0${j}`} style={{ backgroundColor: alternateBG, border: '1px solid #000000', verticalAlign: 'top' }} value=""></CellDirective>
                                        );
                                        items.push(
                                            <CellDirective key={`border1${j}`} wrap={true} style={{ backgroundColor: alternateBG, border: '1px solid #000000', verticalAlign: 'top' }} value=""></CellDirective>
                                        );
                                        items.push(
                                            <CellDirective key={`border2${j}`} wrap={true} style={{ backgroundColor: alternateBG, border: '1px solid #000000', verticalAlign: 'top' }} value=""></CellDirective>
                                        );
                                        items.push(
                                            <CellDirective key={`border3${j}`} wrap={true} style={{ backgroundColor: alternateBG, border: '1px solid #000000', verticalAlign: 'top' }} value=""></CellDirective>
                                        );
                                        items.push(
                                            <CellDirective key={`border4${j}`} wrap={true} style={{ backgroundColor: alternateBG, border: '1px solid #000000', verticalAlign: 'top' }} value=""></CellDirective>
                                        );
                                        items.push(
                                            <CellDirective key={`border5${j}`} style={{ backgroundColor: alternateBG, border: '1px solid #000000', verticalAlign: 'top' }} value=""></CellDirective>
                                        );
                                    }

                                    return <RowDirective key={i}><CellsDirective> {items} </CellsDirective> </RowDirective>
                                })
                            }
                        </RowsDirective>
                        <ColumnsDirective>
                            <ColumnDirective key="dateCol" width={130}></ColumnDirective>
                            <ColumnDirective key="vehicleCol" width={130}></ColumnDirective>
                            <ColumnDirective key="categoryCol" width={90}></ColumnDirective>
                            <ColumnDirective key="driverCol" width={230}></ColumnDirective>
                            {
                                (() => {
                                    const columns = [];
                                    for (let i = 1; i <= excelData.maxJobCount; i++) {
                                        columns.push(
                                            <ColumnDirective key={`${i}0`} width={62}></ColumnDirective>
                                        );
                                        columns.push(
                                            <ColumnDirective key={`${i}1`} wrap={true} width={200}></ColumnDirective>
                                        );
                                        columns.push(
                                            <ColumnDirective key={`${i}2`} wrap={true} width={200}></ColumnDirective>
                                        );
                                        columns.push(
                                            <ColumnDirective key={`${i}3`} wrap={true} width={150}></ColumnDirective>
                                        );
                                        columns.push(
                                            <ColumnDirective key={`${i}4`} wrap={true} width={200}></ColumnDirective>
                                        );
                                        columns.push(
                                            <ColumnDirective key={`${i}5`} width={70}></ColumnDirective>
                                        );
                                    }
                                    return columns;
                                })()
                            }
                        </ColumnsDirective>
                    </SheetDirective>
                </SheetsDirective>
            </SpreadsheetComponent>
        </div>
    )
});

export default OrderExcel;