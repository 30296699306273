import React from "react";
import { ColumnDirective, ColumnsDirective } from "@syncfusion/ej2-react-grids";
import { CellDirective, CellsDirective, RowDirective, RowsDirective, SheetDirective, SheetsDirective, SpreadsheetComponent } from "@syncfusion/ej2-react-spreadsheet";
import moment from "moment";
import { SwalRequest } from "../../../../Utils/Swal";
import { HttpAxiosServers } from "../../../../Utils/AxiosHttp";

const VehicleServiceExcel = React.forwardRef((props, ref) => {
    const spreadsheetRef = React.useRef();
    const [excelData, setExcelData] = React.useState([]);

    React.useImperativeHandle(ref, () => {
        return {
            setExcelData(data) {
                setExcelData(data);
            }
        }
    })

    const createdHandler = () => {
        SwalRequest({
            title: 'Exporting Excel File',
            text: 'Please wait...',
            request: async () => new Promise((resolve, reject) => {
                spreadsheetRef.current.autoFit("F");
                spreadsheetRef.current.autoFit("C")
                
                setTimeout(() => {
                    spreadsheetRef.current.saveAsJson().then(response => {
                        const fileName = `Vehicle Service`;
                        const extension = 'Xlsx';
                        var formData = new FormData();
                        formData.append('JSONData', JSON.stringify(response.jsonObject.Workbook));
                        formData.append('FileName', fileName);
                        formData.append('SaveType', extension);
                        formData.append('FileContentType', extension);
                        formData.append('VersionType', extension);
                        formData.append('PDFLayoutSettings', JSON.stringify({ "fitSheetOnOnePage": false }));

                        fetch(`${HttpAxiosServers.kimhock}/Report/ExtractReport`, {
                            method: 'POST',
                            body: formData
                        }).then(response => {
                            response.blob().then(data => {
                                const url = URL.createObjectURL(new Blob([data], { type: 'application/octet-stream' }));
                                const anchor = document.createElement('a');
                                anchor.href = url;
                                anchor.setAttribute('download', `${fileName}.${extension}`);
                                document.body.appendChild(anchor);
                                anchor.click();
                                anchor.remove();
                                resolve();
                            }, () => reject('Failed to generate file.'));
                        }, () => reject('Failed to Extract Report.'));
                    }, () => reject('Failed to saveAsJSON.'));
                }, 1000);
            }),
            successTitle: 'Export is successful!',
            failedTitle: 'Unable to export excel file',
            failedText: (error) => error,
            onSuccess: onCompleted,
            onFailed: onCompleted
        });
    }

    const onCompleted = () => {
        setExcelData([]);
    }

    const driverFormat = (value) => {
        let leadZero = '';
        for (let i = 0; i < value.length; i++) {
            leadZero += '0'
        }

        return leadZero;
    }

    const driverAlignment = (value) => {
        if (value?.length > 0) {
            if (value.substring(0, 1) === '0') return 'center'
        }

        return 'left';
    }

    return (
        excelData.length > 0 && <div style={{display: 'none'}}>
            <SpreadsheetComponent
                id="vehicleServiceExcel"
                ref={spreadsheetRef}
                created={createdHandler}
                showFormulaBar={false}
                showRibbon={false}
                showSheetTabs={false}
                enableContextMenu={false}
                height={'100%'}>
                <SheetsDirective>
                    <SheetDirective>
                        <RowsDirective>
                            <RowDirective>
                                <CellsDirective>
                                    <CellDirective style={{ backgroundColor: '#D0CECE', border: '1px solid #000000' }} value="From"></CellDirective>
                                    <CellDirective style={{ backgroundColor: '#D0CECE', border: '1px solid #000000' }} value="To"></CellDirective>
                                    <CellDirective style={{ backgroundColor: '#D0CECE', border: '1px solid #000000' }} value="Vehicle"></CellDirective>
                                    <CellDirective style={{ backgroundColor: '#D0CECE', border: '1px solid #000000' }} value="Type"></CellDirective>
                                    <CellDirective style={{ backgroundColor: '#D0CECE', border: '1px solid #000000' }} value="Current Location"></CellDirective>
                                    <CellDirective style={{ backgroundColor: '#D0CECE', border: '1px solid #000000' }} value="Garage/Contractor"></CellDirective>
                                    <CellDirective style={{ backgroundColor: '#D0CECE', border: '1px solid #000000' }} value="Alert Me"></CellDirective>
                                    <CellDirective style={{ backgroundColor: '#D0CECE', border: '1px solid #000000' }} value="Current GPS mileage (km)"></CellDirective>
                                    <CellDirective style={{ backgroundColor: '#D0CECE', border: '1px solid #000000' }} value="Alert Me (Mileage, Km)"></CellDirective>
                                    <CellDirective style={{ backgroundColor: '#D0CECE', border: '1px solid #000000' }} value="Status"></CellDirective>
                                    <CellDirective style={{ backgroundColor: '#D0CECE', border: '1px solid #000000' }} value="Remark"></CellDirective>
                                    <CellDirective style={{ backgroundColor: '#D0CECE', border: '1px solid #000000' }} value="Cost $"></CellDirective>
                                    <CellDirective style={{ backgroundColor: '#D0CECE', border: '1px solid #000000' }} value="Report Date"></CellDirective>
                                </CellsDirective>
                            </RowDirective>
                            {excelData.length > 0 && excelData.map((value, index) => {
                                return (
                                    <RowDirective key={index}>
                                        <CellsDirective>
                                            <CellDirective format="@" style={{ border: '1px solid #000000', textAlign: 'left' }} value={moment(value.service_from_time).format("DD-MMM-YYYY")}></CellDirective>
                                            <CellDirective format="@" style={{ border: '1px solid #000000', textAlign: 'left' }} value={moment(value.service_to_time).format("DD-MMM-YYYY")}></CellDirective>
                                            <CellDirective format={driverFormat(value.service_vehicle)} style={{ border: '1px solid #000000', textAlign: driverAlignment(value.service_vehicle) }} value={value.service_vehicle}></CellDirective>
                                            <CellDirective style={{ border: '1px solid #000000', textAlign: 'left' }} value={value?.service_type_name ?? '-'}></CellDirective>
                                            <CellDirective style={{ border: '1px solid #000000', textAlign: 'left' }} value={value?.current_location_name ?? '-'}></CellDirective>
                                            <CellDirective style={{ border: '1px solid #000000', textAlign: 'left' }} value={value?.service_garage_name ?? '-'}></CellDirective>
                                            <CellDirective format="@" style={{ border: '1px solid #000000', textAlign: 'left' }} value={value?.service_alert_date ? moment(value.service_alert_date).format("DD-MMM-YYYY") : '-'}></CellDirective>
                                            <CellDirective format="@" style={{ border: '1px solid #000000', textAlign: 'left' }} value={'-'}></CellDirective>
                                            <CellDirective format="@" style={{ border: '1px solid #000000', textAlign: 'left' }} value={value?.service_alert_mileage ?? '-'}></CellDirective>
                                            <CellDirective style={{ border: '1px solid #000000', textAlign: 'left' }} value={value?.service_status ? value?.service_status === 1 ? 'open' : 'closed' : '-'}></CellDirective>
                                            <CellDirective style={{ border: '1px solid #000000', textAlign: 'left' }} value={value?.service_remarks ?? '-'}></CellDirective>
                                            <CellDirective style={{ border: '1px solid #000000', textAlign: 'right' }} value={value?.service_cost ?? '-'}></CellDirective>
                                            <CellDirective format="@" style={{ border: '1px solid #000000', textAlign: 'left' }} value={moment(value.created_at).format("DD-MMM-YYYY")}></CellDirective>
                                        </CellsDirective>
                                    </RowDirective>
                                );
                            })}
                        </RowsDirective>
                        <ColumnsDirective>
                            <ColumnDirective width={130}></ColumnDirective>
                            <ColumnDirective width={130}></ColumnDirective>
                            <ColumnDirective width={130}></ColumnDirective>
                            <ColumnDirective width={130}></ColumnDirective>
                            <ColumnDirective width={130}></ColumnDirective>
                            <ColumnDirective width={130}></ColumnDirective>
                            <ColumnDirective width={130}></ColumnDirective>
                            <ColumnDirective width={165}></ColumnDirective>
                            <ColumnDirective width={160}></ColumnDirective>
                            <ColumnDirective width={130}></ColumnDirective>
                            <ColumnDirective width={130}></ColumnDirective>
                            <ColumnDirective width={130}></ColumnDirective>
                            <ColumnDirective width={130}></ColumnDirective>
                        </ColumnsDirective>
                    </SheetDirective>
                </SheetsDirective>
            </SpreadsheetComponent>
        </div>
    );
});

export default VehicleServiceExcel;