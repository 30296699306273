export const UserFeatures = {
    Jobs: 13,
    AdhockJobs: 18
}

function AccountSettings() {
    this.user = null;

    this.setUserInfo = userInfo => {
        if (this.user) {
            this.user = {
                ...this.user,
                ...userInfo
            }
        }
        else this.user = userInfo;
        this._saveChanges();
    }

    this.enableFeatures = (has = [], without = []) => {
        const features = this.user?.features ?? [];
        return features.filter(f => has.includes(f.FeatureID)).length > 0 && features.filter(f => without.includes(f.FeatureID)).length === 0;
    }

    this._saveChanges = () => {
        localStorage.setItem(AccountSettings.key, JSON.stringify(this.user));
    }

    this._init = () => {
        const accountInfo = localStorage.getItem(AccountSettings.key);
        if (accountInfo) this.user = JSON.parse(accountInfo);
    }

    this._init();
}

AccountSettings.key = "accountInfo";

export default AccountSettings;