import React from "react"
import { GridComponent, ColumnDirective, ColumnsDirective, ExcelExport, Filter, Inject, Page, Sort, Toolbar, DetailRow } from "@syncfusion/ej2-react-grids";
import useIsMobile from "../../../../Utils/UseIsMobile";
import {SentryBreadcrumb} from "../../../../Utils/SentryUtils";

const OrderTable = React.forwardRef((props, ref) => {
    const { isMobile } = useIsMobile();
    const grid = React.useRef();
    const [dataSource, setDataSource] = React.useState({
        main: [],
        sub: []
    });

    React.useImperativeHandle(ref, () => {
        return {
            setDataSource(data) {
                setDataSource(data);
            }
        }
    });

    const toolbarItems = [
        { text: 'Expand All', tooltipText: 'Expand All', prefixIcon: 'e-expand', id: 'Grid_Expand' },
        { text: 'Collapse All', tooltipText: 'Collapse All', prefixIcon: 'e-collapse-2', id: 'Grid_Collapse' },
        'ExcelExport',
        { text: 'Search', align: 'Right' }];

    const toolbarClick = (args) => {
        if (grid && args.item.text === 'Excel Export') {
            SentryBreadcrumb.click({
                message: 'Excel Export'
            });
            props.onExcelExport();
        }

        if (args.item.id === 'Grid_Expand') {
            SentryBreadcrumb.click({
                message: 'Grid Expand'
            });
            grid.current.detailRowModule.expandAll();
        }
        else if (args.item.id === "Grid_Collapse") {
            SentryBreadcrumb.click({
                message: 'Grid Collapse'
            });
            grid.current.detailRowModule.collapseAll();
        }
    };

    const rowDataBound = (e) => {
        if (e.data?.HighlightVehicle) {
            e.row.bgColor = "#BAD7ED";
        }
    }

    const childGridOptions = {
        columns: [
            { field: 'OrderCode', headerText: 'JOB', width:110, textAlign: 'left'},
            { field: 'Job', headerText: 'CUSTOMER', width:100, textAlign: 'left'},
            { field: 'Site', headerText: 'SITE', width:100, textAlign: 'left'},
            { field: 'Type', headerText: 'TYPE', width:100, textAlign: 'left'},
            { field: 'Remarks', headerText: 'REMARKS', width:100, textAlign: 'left'},
            { field: 'Tickets', headerText: 'TICKETS', width:100, textAlign: 'left'},
        ],
        rowDataBound: rowDataBound,
        dataSource: dataSource.sub,
        queryString: 'ID'
    }

    return (
        <GridComponent
            ref={grid}
            dataSource={dataSource.main}
            enableAdaptiveUI={isMobile}
            allowTextWrap={true}
            toolbar={toolbarItems}
            toolbarClick={toolbarClick}
            allowExcelExport={true}
            allowPaging={true}
            allowFiltering={true}
            allowSorting={true}
            childGrid={childGridOptions}
            filterSettings={{ type: 'Excel' }}
            sortSettings={{ columns: [{ field: 'Vehicle', direction: 'Ascending' }] }}
            pageSettings={{ pageSize: 20 }}
            >
            <ColumnsDirective>
                <ColumnDirective field='Date' width={110} headerText='DATE' type="date" format='d-MMM-yyyy' />
                <ColumnDirective field='Vehicle' width={120} headerText='VEHICLE' />
                <ColumnDirective field='Driver' width={120} headerText='DRIVER' />
                <ColumnDirective field='JobCount' width={100} headerText='JOBS' />
                <ColumnDirective field='SiteCount' width={100} headerText='SITES' />
                <ColumnDirective field='TicketCount' width={100} headerText='TICKETS' />
            </ColumnsDirective>
            <Inject services={[Filter, Sort, Toolbar, ExcelExport, Page, DetailRow]} />
        </GridComponent>
    );
});

export default OrderTable;