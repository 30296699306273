import { useState, useRef, useEffect } from 'react';
import WarningIcon from '@mui/icons-material/Warning';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import AthenaSettings from '../../Utils/AthenaSettings';
import { useNavigate } from 'react-router-dom';

export default function PageNotFound() {
    const navigate = useNavigate();
    const [title, setTitle] = useState("Redirect")
    const athenaSettings = useRef(new AthenaSettings());
    const hasToken = useRef(false);
    const isEmbed = athenaSettings.current.value.isEmbed;

    useEffect(() => {
        if (!isEmbed) {
            const accountInfo = JSON.parse(localStorage?.accountInfo ?? '{}');
            const token = accountInfo?.ApiKey;
            setTitle(!token ? 'Redirect to Login' : 'Redirect to Scheduler');
            hasToken.current = typeof token === 'string';
        }
        // eslint-disable-next-line
    }, []);

    const handleOnClick = () => {
        if (hasToken.current) navigate('/');
        else navigate('/scheduler');
    }

    return (
        <div className="not-found-container">
            <div className="not-found">
                <WarningIcon />
                <h1>Page is Unavailable</h1>
                {!isEmbed && <ButtonComponent cssClass='e-primary' onClick={handleOnClick}>{title}</ButtonComponent>}
            </div>
        </div>
    );
}