import AthenaSettings, { AthenaSettingsKeys } from "./AthenaSettings";

let wlocateThemeDark = JSON.parse(localStorage.getItem(AthenaSettings.key) ?? '{"darkMode": false}').darkMode;

function ThemeSwitcher(isDark) {
    ThemeSwitcher.setTheme(isDark);
}
ThemeSwitcher.cssDir = 'assets/css/syncfusion';
ThemeSwitcher.setTheme = async (isDark, isInit) => new Promise((resolve) => {
    const athenaSettings = new AthenaSettings();
    const hasDarkModeValue = localStorage.getItem('darkMode');
    
    if (hasDarkModeValue) {
        wlocateThemeDark = JSON.parse(localStorage.getItem('darkMode') ?? 'false');
        localStorage.removeItem('darkMode');
    }
    else wlocateThemeDark = athenaSettings.getItem(AthenaSettingsKeys.darkMode) ?? false;
    

    const themeId = 'theme';
    if (typeof isDark !== 'boolean') {
        if (typeof isDark === 'string') isDark = ['true', '1', 'dark'].includes(isDark.toLowerCase());
        else isDark = false;
    }
    
    if (wlocateThemeDark !== isDark || isInit) {
        wlocateThemeDark = isDark;
        const cssFile = `syncfusion-${isDark ? 'dark' : 'light'}.css`;
        const theme = document.getElementById(themeId);

        if (theme) theme.remove();

        const themeEl = document.createElement('link');
        themeEl.setAttribute('id', themeId);
        themeEl.setAttribute('rel', 'stylesheet');
        themeEl.setAttribute('type', 'text/css');
        themeEl.setAttribute('href', process.env.PUBLIC_URL + `/${ThemeSwitcher.cssDir}/` + cssFile);
        themeEl.onload = function() { 
            setTimeout(() => {
                resolve(); 
            }, 300);  
        }
        document.getElementsByTagName("head")[0].appendChild(themeEl);
        athenaSettings.setItem(AthenaSettingsKeys.darkMode, isDark);
    }
}); 

export default ThemeSwitcher;