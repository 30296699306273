import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { registerLicense } from '@syncfusion/ej2-base';
import { createTheme, ThemeProvider } from '@mui/material';
import { BrowserRouter } from 'react-router-dom';
import * as Sentry from "@sentry/react";

Sentry.init({
  // dsn: "https://4c7feb613fb605d2bd2acb148ed7fc07@o4507423293833216.ingest.us.sentry.io/4507464369307648", //dev
  dsn: "https://ea8b4b73b3a7905767a075ef833310eb@o4507423293833216.ingest.us.sentry.io/4507423297437696", //prod
  maxBreadcrumbs: 50,
  integrations: [
      Sentry.replayIntegration()
  ],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

registerLicense("Mgo+DSMBPh8sVXJ0S0d+XE9AcVRDX3xKf0x/TGpQb19xflBPallYVBYiSV9jS3xTcEVmWH1acnRUQ2BYWA==;" +
"ORg4AjUWIQA/Gnt2VVhhQlFaclhJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxRdkNjXH5cdHZVR2VdWU0=;" +
"NRAiBiAaIQQuGjN/V0Z+XU9EaFtFVmJLYVB3WmpQdldgdVRMZVVbQX9PIiBoS35RdEVlWXpfcnBXRmFZUkZ/;" +
"NjUxMTY3QDMyMzAyZTMxMmUzMFJFY3BDRnZ3aEdreDdHd0J4aUZtbURQM1IxRDFxQm04SFcwakRQcmpyS2M9;" +
"Mgo+DSMBMAY9C3t2VVhhQlFaclhJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxRdkNjXH5cdHZVR2VUV00=;" +
"NjUxMTY5QDMyMzAyZTMxMmUzMGNqRDhwZHIxVW1QQm9vYVk3T0ErNS8wZ3c1azhWZzE4WXJzR2pYZjUzRFE9");

const root = ReactDOM.createRoot(document.getElementById('root'));

const theme = createTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#82A331',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      light: '#F0F0F0',
      main: '#D32F2F',
      white: '#FFFFFF',
      // dark: will be calculated from palette.secondary.main,
      contrastText: '#000',
    },
     // Provide every color token (light, main, dark, and contrastText) when using
     // custom colors for props in Material UI's components.
     // Then you will be able to use it like this: `<Button color="custom">`
     // (For TypeScript, you need to add module augmentation for the `custom` value)
    custom: {
      light: '#ffa726',
      main: '#f57c00',
      dark: '#ef6c00',
      contrastText: 'rgba(0, 0, 0, 0.87)',
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
});

root.render(
  // <React.StrictMode>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </BrowserRouter>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
