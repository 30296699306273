import { Grid } from '@mui/material';
import { forwardRef, useImperativeHandle, useEffect, useState } from 'react';
import AthenaSettings from '../Utils/AthenaSettings';

const ThemeLoader = forwardRef((props, ref) => {
    const [hidden, setHidden] = useState(true);

    const themeClass = props.darkMode ? 'dark-theme' : 'light-theme';

    useImperativeHandle(ref, () => {
        return {
            hide() {
                setHidden(true);
            },
            show() {
                setHidden(false);
            }
        }
    });

    return (
        !hidden && <div id="themeLoader" className={`theme-loader ${themeClass} center-content`}>
            <Grid container spacing={2}>
                <Grid className='center-content' xs={12} item>
                    <div className={`theme-loader-title ${themeClass}`}>
                        {props.darkMode ? 'Switching to Dark Theme' : 'Switching to Light Theme'}
                    </div>
                </Grid>
                <Grid className='center-content' xs={12} item>
                    <div className={`lds-dual-ring ${themeClass}`}></div>
                </Grid>
            </Grid>
        </div> 
    );
});

function ComponentSpinner(props) {
    const athenaSettings = new AthenaSettings();
    const isDarkMode = localStorage.getItem('darkMode') ? JSON.parse(localStorage.getItem('darkMode')) : athenaSettings.getItem('darkMode');
    const themeClass = isDarkMode ? 'dark-theme' : 'light-theme';
    const spinnerParentClass = props.wholePage ? '' : "component-loader"
    const spinnerClass = props.wholePage ? '' : "component-spinner"

    const containerStyle = props?.containerStyle ? props?.containerStyle : {};
    const loaderStyle = props?.loaderStyle ? props?.loaderStyle : {};

    return (
        <div id="themeLoader" className={`${spinnerParentClass} theme-loader ${themeClass} center-content`} style={{width: '100%', ...containerStyle}}>
            <div className={`${spinnerClass} lds-dual-ring ${themeClass}`} style={loaderStyle}></div>
        </div> 
    );
}

const DialogSpinner = forwardRef((props, ref) => {
    const [visible, setVisible] = useState(props?.show ?? false);

    useEffect(() => {
        if (props.visible != null) {
            setVisible(props.visible);
        }
    }, [props.visible]);

    useImperativeHandle(ref, () => {
        return {
            show() {
                setVisible(true);
            },
            hide() {
                setVisible(false);
            },
            isVisible() {
                return visible;
            }
        }
        
    });

    return (
        visible && <div className='modal-spinner-container'>
            <div className={'lds-dual-ring dark-theme'}></div>
        </div> 
    );
})

export {
    ThemeLoader,
    ComponentSpinner,
    DialogSpinner
}