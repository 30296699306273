import { AccordionComponent, AccordionItemDirective, AccordionItemsDirective } from '@syncfusion/ej2-react-navigations';
import * as React from 'react';
import { Grid } from "@mui/material";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import { ComboBoxComponent } from '@syncfusion/ej2-react-dropdowns';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import OrderFilterData from '../Models/OrderFilter';
import { Controller, useForm } from 'react-hook-form';
import moment from 'moment';
import {SentryBreadcrumb} from "../../../../Utils/SentryUtils";

const OrderFilterContent = React.forwardRef((props, ref) => {
    const [dataSource, setDataSource] = React.useState({
        vehicles: [],
        customers: [],
        projectSites: [],
        returnSites: [],
        drivers: []
    });

    const { control, reset, handleSubmit, setValue } = useForm({
        defaultValues: {
            DateStart: null,
            DateEnd: null,
            VehicleID: null,
            CustomerCode: null,
            ShipToCode: null,
            ShipToName: null,
            PlantCode: null,
            PlantName: null,
            OrderCode: null
        }
    });

    React.useImperativeHandle(ref, () => {
        return {
            UpdateFilter(filterData) {
                reset(filterData);
            },
            setDataSource(data) {
                setDataSource({
                    ...dataSource,
                    ...data
                });
            }
        }
        // eslint-disable-next-line
    }, []);

    React.useEffect(() => {
        if (props?.filter) {
            setTimeout(() => {
                reset(props.filter);
            }, 500);
        }
        // eslint-disable-next-line
    }, []);

    const dateOnChange = (e) => {
        const dateEnd = moment(e.value).add(1, 'day');
        setValue('DateStart', e.value);
        setValue('DateEnd', dateEnd.toDate());
    }

    const onSubmit = (values) => {
        if (values?.PlantCode) {
            values.OriginCode = values?.PlantCode;
        }
        else if (values?.PlantName) {
            const origins = dataSource.returnSites.filter(f => f.Name === values?.PlantName);
            if (origins.length > 0) {
                values.OriginCode = origins[0].ShipToCode;
            }
        }
        props.filterOnClick(values);
    }

    return (
        <div className="e-card" id="basic_card" style={{ width: '100%' }}>
            <div className="e-card-content" style={{ padding: '5px' }}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={2}>
                        <Grid item lg={4} md={4} sm={12} xs={12}>
                            <Controller
                                name="DateStart"
                                control={control}
                                render={({ field }) => (
                                    <DatePickerComponent
                                        value={field.value}
                                        allowEdit={false}
                                        floatLabelType='Auto'
                                        showClearButton={true}
                                        format="yyyy-MMM-dd"
                                        id="startDatepicker"
                                        placeholder="Date"
                                        change={dateOnChange}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item lg={4} md={4} sm={12} xs={12}>
                            <Controller
                                name="VehicleID"
                                control={control}
                                render={({ field }) => (
                                    <ComboBoxComponent
                                        {...field}
                                        id="vehicle"
                                        floatLabelType='Auto'
                                        dataSource={dataSource.vehicles}
                                        autofill={true}
                                        placeholder="Vehicle"
                                        fields={{ text: 'Name', value: 'VehicleID' }} />
                                )}
                            />
                        </Grid>
                        <Grid item lg={4} md={4} sm={12} xs={12}>
                            <Controller
                                name="Driver"
                                control={control}
                                render={({ field }) => (
                                    <ComboBoxComponent
                                        {...field}
                                        id="driver"
                                        floatLabelType='Auto'
                                        dataSource={dataSource.drivers}
                                        autofill={true}
                                        placeholder="Driver"
                                        fields={{ text: 'Name', value: 'Name' }} />
                                )}
                            />
                        </Grid>
                        <Grid item lg={4} md={4} sm={12} xs={12}>
                            <Controller
                                name="CustomerCode"
                                control={control}
                                render={({ field }) => (
                                    <ComboBoxComponent
                                        {...field}
                                        id="customerCode"
                                        floatLabelType='Auto'
                                        dataSource={dataSource.customers}
                                        autofill={true}
                                        placeholder="Customer/Company"
                                        fields={{ text: 'CustomerName', value: 'CustomerCode' }} />
                                )}
                            />
                        </Grid>
                        <Grid item lg={4} md={4} sm={12} xs={12}>
                            <Controller
                                name="ShipToCode"
                                control={control}
                                render={({ field }) => (
                                    <ComboBoxComponent
                                        {...field}
                                        id="siteCode"
                                        floatLabelType='Auto'
                                        dataSource={dataSource.sites?.filter(f => f.ShipToCode !== "") ?? []}
                                        autofill={true}
                                        placeholder="Site Code"
                                        fields={{ text: 'ShipToCode', value: 'ShipToCode' }} />
                                )}
                            />
                        </Grid>
                        <Grid item lg={4} md={4} sm={12} xs={12}>
                            <Controller
                                name="ShipToName"
                                control={control}
                                render={({ field }) => (
                                    <ComboBoxComponent
                                        {...field}
                                        id="siteName"
                                        floatLabelType='Auto'
                                        dataSource={dataSource.sites?.filter(f => f.ShipToCode !== "") ?? []}
                                        autofill={true}
                                        placeholder="Site Name"
                                        fields={{ text: 'Name', value: 'Name' }} />
                                )}
                            />
                        </Grid>
                        <Grid item lg={4} md={4} sm={12} xs={12}>
                            <Controller
                                name="PlantCode"
                                control={control}
                                render={({ field }) => (
                                    <ComboBoxComponent
                                        {...field}
                                        id="plantCode"
                                        floatLabelType='Auto'
                                        dataSource={dataSource.returnSites}
                                        autofill={true}
                                        placeholder="Plant Code"
                                        fields={{ text: 'ShipToCode', value: 'ShipToCode' }} />
                                )}
                            />
                        </Grid>
                        <Grid item lg={4} md={4} sm={12} xs={12}>
                            <Controller
                                name="PlantName"
                                control={control}
                                render={({ field }) => (
                                    <ComboBoxComponent
                                        {...field}
                                        id="plantName"
                                        floatLabelType='Auto'
                                        dataSource={dataSource.returnSites}
                                        autofill={true}
                                        placeholder="Plant Name"
                                        fields={{ text: 'Name', value: 'Name' }} />
                                )}
                            />
                        </Grid>
                        <Grid item lg={4} md={4} sm={12} xs={12}>
                            <Controller
                                name="OrderCode"
                                control={control}
                                render={({ field }) => (
                                    <TextBoxComponent {...field} placeholder="Order Code" floatLabelType="Auto" />
                                )}
                            />
                        </Grid>
                        <Grid className="center-content" item xs={12}>
                            <button type='button' className="e-btn e-outline e-warning btn-custom-width" onClick={props.onClear} style={{ marginBottom: '5px', marginTop: '10px', marginRight: '8px' }}>
                                Clear
                            </button>
                            <button type='submit' className="e-btn e-outline e-success btn-custom-width" style={{ marginBottom: '5px', marginTop: '10px' }}>
                                Filter Report
                            </button>
                        </Grid>
                    </Grid>
                </form>
            </div>
        </div>
    );
});

const OrderFilter = React.forwardRef((props, ref) => {
    // const httpAxios = props.axios;
    const filter = React.useRef(new OrderFilterData());
    const filterData = React.useRef({
        drivers: [],
        customers: [],
        sites: [],
        returnSites: [],
        vehicles: []
    });
    const [headerTitle, setHeaderTitle] = React.useState("Filter");
    const [isExpanded, setIsExpanded] = React.useState(true);
    const orderFilterRef = React.useRef(null);

    React.useImperativeHandle(ref, () => {
        return {
            setDataSource(data) {
                filterData.current = {
                    ...filterData.current,
                    ...data
                };
                orderFilterRef.current.setDataSource(filterData.current);
            }
        }
        // eslint-disable-next-line
    }, []);

    // React.useEffect(() => {
    //     httpAxios.post(`zone/scrap%20yard?Token=${httpAxios.token}`).then((response) => {
    //         setPlantCodes(response);
    //     });
    //     // eslint-disable-next-line
    // }, []);

    const expandAccordion = (event) => {
        setIsExpanded(event.isExpanded);
        setHeaderTitle(event.isExpanded ? "Filter" : filter.current.getFilterHeader());
        //prevent UI stutters
        setTimeout(() => {
            orderFilterRef.current.setDataSource(filterData.current);
            orderFilterRef.current.UpdateFilter(filter.current.filter);
        }, 300);
    }

    const filterHandler = (filterData) => {
        SentryBreadcrumb.click({
            message: 'Filter Report',
            data: filterData
        });
        filterData = new OrderFilterData(filterData);
        filter.current = filterData;
        props.onFilter(filterData.getFilter());
    };

    const clearFilterHandler = () => {
        SentryBreadcrumb.click({
            message: 'Clear filter'
        });
        filter.current = new OrderFilterData();
        orderFilterRef.current.UpdateFilter(filter.current.filter);
    };

    const animation = { expand: { effect: '', duration: 0, easing: '' }, collapse: { effect: '', duration: 0, easing: '' } };
    return (
        <>
            <AccordionComponent
                expanded={expandAccordion} animation={animation} expandMode='Single'
            >
                <AccordionItemsDirective>
                    <AccordionItemDirective id='filter-acrdn' expanded={isExpanded} header={headerTitle} content={() => (
                        <OrderFilterContent
                            ref={orderFilterRef}
                            dataSource={props.dataSource}
                            onClear={clearFilterHandler}
                            filterOnClick={filterHandler}
                        />
                    )} />
                </AccordionItemsDirective>
            </AccordionComponent>
        </>
    );
});

export default React.memo(OrderFilter);