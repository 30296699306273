import { Axios } from "axios";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import AccountSettings from "./AccountSettings";
//import Swal from "sweetalert2";


export const HttpAxiosServers = {
    //FIX: revert to live kimhock url
    kimhock: "https://findplus.w-locate.com/kimhock/scheduler",
    kimhockStaging: "https://findplus.w-locate.com/kimhock/staging",
    athenaWlocate: "https://wlocateapi.chooseathena.com/api/"
    // athenaWlocate: "http://127.0.0.1:8000/api/"
}

export const baseUrl = HttpAxiosServers.kimhockStaging;

//NOTE: Detect if token is expired or invalid
//Dili mag return ang api ug 401(Unauthorized) kung ang token kay kay na invalidate or expired
export default function HttpAxios(options) {

    // let controller = new AbortController();
    this.controller = new AbortController();
    this.accountInfo = localStorage.accountInfo ? JSON.parse(localStorage.accountInfo) : null;
    this.token = options?.token ?? this.accountInfo?.ApiKey;
    this.baseUrl = options?.baseUrl ?? baseUrl;
    const axiosInstance = new Axios({
        // baseURL: "https://findplus.w-locate.com/kimhock/scheduler",//"https://localhost:5001",
        baseURL: this.baseUrl,
        timeout: 6000000,
        headers: {
            common: {
                // "Authorization": `Bearer ${options?.token ?? this.accountInfo.RefreshToken}`,
                //options.token used for embed pages
                "Token": this.token,
                'Accept': "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
            },
        },
        validateStatus: function (status) {
            return status >= 200 && status < 400
        }
    });

    this.abortRequests = () => {
        this.controller.abort();
    };

    this.noInternetException = (e) => {
        if (e.code && e.code === "ERR_NETWORK") {
            if (!Swal.isVisible()) {
                Swal.fire({
                    title: "No Internet Connection!",
                    text: 'Try again later.',
                    icon: 'error',
                    confirmButtonColor: 'maroon',
                });
            }
            else {
                Swal.update({
                    title: "No Internet Connection!",
                    text: 'Try again later.',
                    icon: 'error',
                    confirmButtonColor: 'maroon',
                });
            }

            return false;
        }
        else if (e.code) {
            throw e;
        }
    }

    this.get = (url) => {
        return axiosInstance.get(
            url,
            {
                signal: this.controller.signal,
            }).then((response) => response, (e) => this.noInternetException(e));
    };

    this.getAxiosInstance = () => axiosInstance;

    this.post = (url, data) => {
        return axiosInstance
            .post(url, JSON.stringify(data), {
                signal: this.controller.signal,
            })
            .then((response) => {
                if (response.status === 201 || response.status === 200) return JSON.parse(response.data);
                else {
                    if (response.status >= 300) {
                        throw response.data;
                    }
                    return false;
                }
            }, (e) => this.noInternetException(e));
    };

    this.put = (url, data) => {
        return axiosInstance
            .put(url, JSON.stringify(data), {
                signal: this.controller.signal,
            })
            .then((response) => {
                if (response.status === 201 || response.status === 200) return JSON.parse(response.data);
                else {
                    if (response.status >= 300) {
                        throw response.data;
                    }
                    return false;
                }
            }, (e) => this.noInternetException(e));
    };

    this.patch = (url, data) => {
        return axiosInstance
            .patch(url, JSON.stringify(data), {
                signal: this.controller.signal,
            })
            .then((response) => {
                if (response.status === 201 || response.status === 200) return JSON.parse(response.data);
                else {
                    if (response.status >= 300) {
                        throw response.data;
                    }
                    return false;
                }
            }, (e) => this.noInternetException(e));
    };

    this.delete = (url) => {
        return axiosInstance.delete(
            url,
            {
                signal: this.controller.signal,
            }).then((response) => {
                const data = JSON.parse(response.data);
                if (response.status === 200) return data;
                else {
                    if (response.status >= 300) {
                        throw response.data;
                    }

                    return false;
                }
            }, (e) => this.noInternetException(e));;
    };
}

export function useHttpAxios(options) {
    const sessionKey = AccountSettings.key;
    const [isInitialized, setIsInitialized] = useState(false);
    const [httpAxios, setHttpAxios] = useState([]);
    const [accountDetails, setAccountDetails] = useState(null);

    useEffect(() => {
        _initHttpAxios();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (httpAxios && accountDetails) {
            setIsInitialized(true);
        }
        // eslint-disable-next-line
    }, [httpAxios, accountDetails]);

    const _initHttpAxios = async () => {
        let axios = new HttpAxios(options);
        const accountInfo = JSON.parse(localStorage.getItem(sessionKey));
        const token = options?.token ?? accountInfo?.ApiKey;
        const response = await axios.get(`Account?Token=${token}`);
        if (response.status === 204) {
            // localStorage.removeItem(sessionKey);
            // window.location.reload();
        }
        else {
            const responseData = JSON.parse(response.data);
            responseData.ApiKey = token;
            localStorage.setItem(sessionKey, JSON.stringify(responseData));
            _generateHttpAxiosInstance({
                ...options
            });
        }
    }

    const _generateHttpAxiosInstance = (customOptions) => {
        if (options?.baseUrls) {
            const axiosIntances = options.baseUrls.map((url) => new HttpAxios({
                ...customOptions,
                baseUrl: url
            }));
            setHttpAxios(axiosIntances);
        }
        else {
            setHttpAxios([
                new HttpAxios({
                    ...customOptions,
                    baseUrl: HttpAxiosServers.kimhock
                })
            ])
        }

        const accountInfo = localStorage.getItem(sessionKey);
        if (accountInfo) setAccountDetails(JSON.parse(accountInfo));
    }

    return [...httpAxios, isInitialized, accountDetails];
}