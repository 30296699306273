import moment from 'moment';

export default function OvertimeFilterModel(filter) {
    this.filter = filter ?? {};

    this.getFilter = function () {
        return this.filter;
    }

    this.getFilterHeader = function () {
        let headerTitle = "Filter";
        const keys = Object.keys(this.filter);
        if (keys.length) {
            headerTitle += ' - ';
            keys.forEach((key) => {
                if (this.filtersLabels[key]) {
                    if (key === 'StartDate' || key === 'EndDate') headerTitle += `${this.filtersLabels[key]}: ${moment(this.filter[key]).format('yyyy-MMM-DD HH:mm')}, `
                    else headerTitle += `${this.filtersLabels[key]}: ${this.filter[key]}, `
                };
            });

            return headerTitle.substring(0, (headerTitle.length - 2));
        }

        console.log(headerTitle);
        return headerTitle;
    }

    this.init = function () {
        //Remove key value pair if the value is null or undefined
        Object.keys(this.filter).forEach((key) => {
            if (!this.filter[key]) delete this.filter[key];
        });
                  
        if (this.filter.DateStart) this.filter.DateStart = moment(this.filter.DateStart).utc().toISOString();
        if (this.filter.DateEnd) this.filter.DateEnd = moment(this.filter.DateEnd).utc().toISOString();
      }

    this.init();
}

OvertimeFilterModel.prototype.filtersLabels = {
    StartDate: "Start Date",
    EndDate: "End Date",
    Vehicle: "Vehicle",
    ShipToCode: "Project Site",
    OriginCode: "Return Site"
}