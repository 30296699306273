import { AccordionComponent, AccordionItemDirective, AccordionItemsDirective } from '@syncfusion/ej2-react-navigations';
import * as React from 'react';
import { Grid } from "@mui/material";
import { DateTimePickerComponent } from "@syncfusion/ej2-react-calendars";
import { ComboBoxComponent } from '@syncfusion/ej2-react-dropdowns';
import { Controller, useForm } from 'react-hook-form';
import moment from 'moment';
import VehServiceFilterModel from '../Models/VehServiceFilterModel';

const VehicleServiceContent = React.forwardRef((props, ref) => {
    const [dataSource, setDataSource] = React.useState({
        vehicles: []
    });

    const { control, reset, handleSubmit, setValue } = useForm({
        defaultValues: {
            service_from_time: null,
            service_to_time: null,
            service_vehicle_id: null
        }
    });

    React.useImperativeHandle(ref, () => {
        return {
            UpdateFilter(filterData) {
                reset(filterData);
            },
            setDataSource(data) {
                setDataSource({
                    ...dataSource,
                    ...data
                });
            }
        }
        // eslint-disable-next-line
    }, []);

    React.useEffect(() => {
        if (props?.filter) {
            setTimeout(() => {
                reset(props.filter);
            }, 500);
        }
        // eslint-disable-next-line
    }, []);

    const onSubmit = (values) => {
        if (values.DateStart) {
            values.DateEnd = moment(values.DateStart).set('hour', 23).set('minute', 59);
        }
        props.filterOnClick(values);
    }

    return (
        <div className="e-card" id="basic_card" style={{ width: '100%' }}>
            <div className="e-card-content" style={{ padding: '5px' }}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={2}>
                        <Grid item lg={4} md={4} sm={12} xs={12}>
                            <Controller
                                name="service_vehicle_id"
                                control={control}
                                render={({ field }) => (
                                    <ComboBoxComponent
                                        {...field}
                                        id="vehicle"
                                        floatLabelType='Auto'
                                        dataSource={dataSource.vehicles}
                                        autofill={true}
                                        placeholder="Vehicle"
                                        fields={{ text: 'Name', value: 'VehicleID' }} />
                                )}
                            />
                        </Grid>
                        <Grid item lg={4} md={4} sm={12} xs={12}>
                            <Controller
                                name="service_from_time"
                                control={control}
                                render={({ field }) => (
                                    <DateTimePickerComponent
                                        value={field.value}
                                        allowEdit={false}
                                        floatLabelType='Auto'
                                        showClearButton={true}
                                        format="yyyy-MMM-dd HH:mm"
                                        timeFormat='HH:mm'
                                        id="startDatepicker"
                                        placeholder="From"
                                        change={(e) => setValue('service_from_time', e.value)}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item lg={4} md={4} sm={12} xs={12}>
                            <Controller
                                name="service_to_time"
                                control={control}
                                render={({ field }) => (
                                    <DateTimePickerComponent
                                    value={field.value}
                                        allowEdit={false}
                                        floatLabelType='Auto'
                                        showClearButton={true}
                                        format="yyyy-MMM-dd HH:mm"
                                        timeFormat='HH:mm'
                                        id="endDatepicker"
                                        placeholder="To"
                                        change={(e) => setValue('service_to_time', e.value)}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid className="center-content" item xs={12}>
                            <button type='button' className="e-btn e-outline e-warning btn-custom-width" onClick={props.onClear} style={{ marginBottom: '5px', marginTop: '10px', marginRight: '8px' }}>
                                Clear
                            </button>
                            <button type='submit' className="e-btn e-outline e-success btn-custom-width" style={{ marginBottom: '5px', marginTop: '10px', marginRight: '8px' }}>
                                Filter Report
                            </button>
                        </Grid>
                    </Grid>
                </form>
            </div>
        </div>
    );
});

const VehicleServiceFilter = React.forwardRef((props, ref) => {
    // const httpAxios = props.axios;
    const filter = React.useRef(new VehServiceFilterModel());
    const filterData = React.useRef({
        vehicles: [],
    });
    const [headerTitle, setHeaderTitle] = React.useState("Filter");
    const [isExpanded, setIsExpanded] = React.useState(true);
    const vehServiceContentRef = React.useRef(null);

    React.useImperativeHandle(ref, () => {
        return {
            setDataSource(data) {
                filterData.current = {
                    ...filterData.current,
                    ...data
                };
                vehServiceContentRef.current.setDataSource(filterData.current);
            }
        }
        // eslint-disable-next-line
    }, []);

    // React.useEffect(() => {
    //     httpAxios.post(`zone/scrap%20yard?Token=${httpAxios.token}`).then((response) => {
    //         setPlantCodes(response);
    //     });
    //     // eslint-disable-next-line
    // }, []);

    const expandAccordion = (event) => {
        setIsExpanded(event.isExpanded);
        setHeaderTitle(event.isExpanded ? "Filter" : filter.current.getFilterHeader());
        //prevent UI stutters
        setTimeout(() => {
            vehServiceContentRef.current.setDataSource(filterData.current);
            vehServiceContentRef.current.UpdateFilter(filter.current.filter);
        }, 300);
    }

    const filterHandler = (filterData) => {
        filterData = new VehServiceFilterModel(filterData);
        filter.current = filterData;
        props.onFilter(filterData.getFilter());
    };

    const clearFilterHandler = () => {
        filter.current = new VehServiceFilterModel();
        vehServiceContentRef.current.UpdateFilter(filter.current.filter);
    };

    const animation = { expand: { effect: '', duration: 0, easing: '' }, collapse: { effect: '', duration: 0, easing: '' } };
    return (
        <>
            <AccordionComponent
                expanded={expandAccordion} animation={animation} expandMode='Single'
            >
                <AccordionItemsDirective>
                    <AccordionItemDirective id='filter-acrdn' expanded={isExpanded} header={headerTitle} content={() => (
                        <VehicleServiceContent
                            ref={vehServiceContentRef}
                            dataSource={props.dataSource}
                            onClear={clearFilterHandler}
                            filterOnClick={filterHandler}
                            exportOnClick={props.onExcelExport}
                        />
                    )} />
                </AccordionItemsDirective>
            </AccordionComponent>
        </>
    );
});

export default React.memo(VehicleServiceFilter);