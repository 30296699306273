import React from "react";
import { useParams } from "react-router-dom";
import { HttpAxiosServers, useHttpAxios } from "../../../Utils/AxiosHttp";
import SideBar from "../../../Components/SideBar";
import VehicleServiceFilter from "./Components/VehicleServiceFilter";
import VehicleServiceExcel from "./Components/VehicleServiceExcel";
import Spacing from "../../../Components/Spacing";
import { SwalRequest } from "../../../Utils/Swal";
import VehicleServiceTable from "./Components/VehicleServiceTable";

export default function VehicleService(props) {
    const { token } = useParams();
    const vehicleServices = React.useRef([]);
    const filterRef = React.useRef();
    const tableRef = React.useRef();
    const excelRef = React.useRef();
    const [httpAthena, httpKimhock, isInitialized] = useHttpAxios({
        token: token,
        baseUrls: [
            HttpAxiosServers.athenaWlocate,
            HttpAxiosServers.kimhock
        ]
    })

    React.useEffect(() => {
        if (props?.isEmbed) {
            SideBar.embed();
        }
        // eslint-disable-next-line
    }, []);

    React.useEffect(() => {
        if (isInitialized) {
            initService();
        }
        // eslint-disable-next-line
    }, [isInitialized]);

    const initService = () => {
        filterVehicleService({}, true);
    }

    const filterVehicleService = (payload, isInit) => {
        SwalRequest({
            title: 'Fetching Vehicle Service Data',
            text: 'Please wait...',
            request: async () => {
                if (isInit) {
                    const vehicles = await httpKimhock.post(`Vehicle?Token=${httpKimhock.token}`);
                    filterRef.current.setDataSource({
                        vehicles: vehicles
                    });
                }

                payload.company_id = httpAthena.accountInfo.CompanyID;
                const vehicleMaintenance = await httpAthena.post(`servicemaintenance/report`, payload);
                vehicleServices.current = vehicleMaintenance;
                tableRef.current.setDataSource(vehicleMaintenance);
            },
            failedTitle: "Unable to fetch Vehicle Service Data"
        });
    }

    const excelExportHandler = () => {
        excelRef.current.setExcelData(vehicleServices.current)
    }

    return (
        <>
            <VehicleServiceFilter ref={filterRef} onFilter={filterVehicleService} />
            <Spacing />
            <VehicleServiceTable ref={tableRef} onExcelExport={excelExportHandler} />
            <VehicleServiceExcel ref={excelRef} />
        </>
    );
}