import { useEffect, useState } from "react";
import AthenaSettings, { AthenaSettingsKeys } from "../Utils/AthenaSettings";
import ThemeSwitcher from "../Utils/ThemeSwitcher";
import { ComponentSpinner } from "./Loader";
import { useParams } from "react-router-dom";

export default function EmbedContainer(props) {
    const { theme } = useParams();
    const [showSpinner, setShowSpinner] = useState(true);

    useEffect(() => {
        if (theme !== undefined) {
            ThemeSwitcher.setTheme(theme, true).then(() => setShowSpinner(false), () => setShowSpinner(false));
        }
        else {
            setShowSpinner(false);
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const settings = new AthenaSettings();
        settings.setItem(AthenaSettingsKeys.isEmbed, true);
    }, []);

    return (
        <>
            {showSpinner && <ComponentSpinner wholePage={true} containerStyle={{height: '103vh'}} loaderStyle={{marginTop: '-20px'}} />}
            {props.children}
        </>
    );
}